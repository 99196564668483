import React, { useEffect, useState } from "react";
import {
  LABEL_JOB_DETAILS,
  LABEL_APPLIANCE_DETAILS,
  LABEL_JOB,
} from "../../../constant/label_constant";
import httpRequest from "../../../utils/Http";
import DATA_NOT_FOUND from "../../404";
import BasicDetailsTab from "./BasicDetailsTab";
import MainBody from "../../../component/MainBody";
import ApplianceDetailsTab from "./ApplianceDetailsTab";
import BookingRecentActivityTab from "./BookingRecentActivityTab";
import UpcountryTab from "./UpcountryTab";
import SparePartsTab from "./SparePartsTab";
import { useSelector } from "react-redux";
import MiscChargeTab from "./MiscChargeTab";

const BookingDetails = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [bid, setBid] = useState(null);
  const [bookingData, setBookingID] = useState({});
  const [bookingUpcountry, setBookingUpcountry] = useState({});
  const [userApplianceDetails, setUserApplianceDetails] = useState({});
  const [bookingCharges, setBookingCharges] = useState({});
  const [bookingHistory, setBookingHistory] = useState([]);
  const [spareParts, setSpareParts] = useState([]);
  const [miscellaneousCharge, setMiscellaneousCharge] = useState([]);
  const [reloadList, setReload] = useState(true);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const ebid = urlParams.get("bid");
      try {
        const bid = atob(ebid);
        setBid(bid);
        const data = await httpRequest(
          "getbookingdetails?id=" + bid,
          {},
          "GET"
        );

        if (data.status === true) {
          setBookingID(data.bookingData);
          setUserApplianceDetails(data.userApplianceDetails);
          setBookingUpcountry(data.bookingUpcountry);
          setBookingCharges(data.bookingCharges);
          setBookingHistory(data.bookingHistory);
          setSpareParts(data.spareParts);
        } else {
          setBookingID([]);
        }
      } catch (error) {
        setBookingID([]);
      }
    })();
  }, [reloadList]);

  useEffect(() => {
    (async () => {
      if (bid && reloadList) {
        const data = await httpRequest(
          "getmisccharge/" + bid,
          {},
          "GET"
        );
        if(data.status === true && data.data){
          setMiscellaneousCharge(data.data)
          setReload(false);
        }
      }
    })();
  }, [bid, reloadList]);

  return (
    <>
      <MainBody
        breadcrumbTitle={`${LABEL_JOB} Details`}
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <>
            {bid === null && (
              <>
                <DATA_NOT_FOUND />
              </>
            )}
            {bid !== null && Object.keys(bookingData).length === 0 && (
              <>
                <DATA_NOT_FOUND />
              </>
            )}

            {bid !== null && Object.keys(bookingData).length > 0 && (
              <>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="card-header p-2">
                            <ul className="nav nav-pills">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  href="#booking_details"
                                  data-toggle="tab"
                                >
                                  {LABEL_JOB_DETAILS}
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#appliace_details"
                                  data-toggle="tab"
                                >
                                  {LABEL_APPLIANCE_DETAILS}
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#upcountry"
                                  data-toggle="tab"
                                >
                                  Upcountry
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#miscellaneous"
                                  data-toggle="tab"
                                >
                                  Miscellaneous Charge
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#spare_parts"
                                  data-toggle="tab"
                                >
                                  Part Ordered
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#recent_activity"
                                  data-toggle="tab"
                                >
                                  Recent Activity
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="card-body">
                            <div className="tab-content">
                              <div
                                className="active tab-pane"
                                id="booking_details"
                              >
                                <BasicDetailsTab bookingData={bookingData} />
                              </div>
                              <div className=" tab-pane" id="appliace_details">
                                <ApplianceDetailsTab
                                  bookingCharges={bookingCharges}
                                  userApplianceDetails={userApplianceDetails}
                                  bookingUpcountry={bookingUpcountry}
                                  spareParts={spareParts}
                                  loggedInUser={loggedInUser}
                                />
                              </div>
                              <div className=" tab-pane" id="upcountry">
                                <UpcountryTab
                                  bookingUpcountry={bookingUpcountry}
                                />
                              </div>
                              <div className=" tab-pane" id="miscellaneous">
                                <MiscChargeTab
                                  miscellaneousCharge={miscellaneousCharge}
                                  loggedInUser={loggedInUser}
                                  setReload={setReload}
                                />
                              </div>
                              <div className=" tab-pane" id="spare_parts">
                                <SparePartsTab 
                                  spareParts={spareParts} 
                                  setReload={setReload}

                                />
                              </div>
                              <div className=" tab-pane" id="recent_activity">
                                <BookingRecentActivityTab
                                  bookingHistory={bookingHistory}
                                  setBookingHistory={setBookingHistory}
                                  bid={bid}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default BookingDetails;
