import React, { useEffect, useState } from "react";
import {
  getdropdownList,
  isValidEmail,
  isValidMobile,
} from "../../../utils/HelperFunction";
import Select from "react-select";
import "../../../asset/css/suggestion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UploadFileToS3 } from "../../../utils/S3File";
import { LABEL_JOB } from "../../../constant/label_constant";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import { useSelector } from "react-redux";
import { CANCELLED_STATUS } from "../../../constant/status_constant";

const CreateBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({ booking_type: "Booking" });
  const [cityOption, setCityOption] = useState([]);
  const [sellerChannelOption, setSellerChannelOption] = useState([]);
  const [typeGroupoptions, setTypeGroup] = useState([]);
  const [partnerlist, setPartnerlist] = useState([]);
  const [applianceOption, setOpAppliance] = useState([]);
  const [modelNumberList, setModelNumberList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [capacityList, setCapacityList] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [serviceCategory, setServiceCategory] = useState([]);
  const [serviceChecked, setServiceChecked] = useState([]);
  const [totalServiceCharge, setTotalServiceCharge] = useState(0);
  const [bidCalltypeList, setBidCalltypeList] = useState([]);
  const [bookingUpcountry, setBookingUpcountry] = useState([]);
  const [modelFreeze, setModelFreeze] = useState(false);
  const { bid } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const parentbid = searchParams.get("parentbid");

  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    (async () => {
      if (bid) {
        try {
          const id = atob(bid);
          const data = await httpRequest(
            "getbookingdetails?ishistory=0&id=" + id,
            {},
            "GET"
          );

          if (data.status === true) {
            if (
              !data.bookingData.sc_closed_date &&
              !data.bookingData.closed_date
            ) {
              const purchase_date = new Date(
                data.userApplianceDetails.purchase_date
              );
              const appointment_date = new Date(data.bookingData.booking_date);
              setFormData({
                ...formData,
                booking_id: data.bookingData.booking_id,
                mobile_number: data.bookingData.booking_primary_mobile_number,
                customer_name: data.bookingData.user_detail.customer_name,
                alternate_mobile_number:
                  data.bookingData.booking_alternate_mobile_number,
                email_id: data.bookingData.user_detail.user_email,
                pincode: data.bookingData.booking_pincode,
                address: data.bookingData.booking_address,
                city: data.bookingData.booking_city,
                state_id: data.bookingData.booking_state,
                assigned_sc_id: data.bookingData.assigned_sc_id,
                user_id: data.bookingData.user_id,
                sellerChannel: {
                  label: data.bookingData.source_channel.source,
                  value: data.bookingData.source_channel.id,
                },
                calltype: {
                  label: data.bookingData.calltype_group.type,
                  value: data.bookingData.calltype_group.id,
                },
                partner_id: data.bookingData.partner_id,
                appliance_id: data.bookingData.appliance_id,
                model_number: data.userApplianceDetails.model_number,
                model_id: data.userApplianceDetails.model_id,
                brand_id: data.userApplianceDetails.brand_id,
                category_id: data.userApplianceDetails.category_id,
                capacity_id: data.userApplianceDetails.capacity_id,
                serial_number: data.userApplianceDetails.serial_number,
                purchase_date: purchase_date,
                appointment_date: appointment_date,
                order_id: data.bookingData.order_id,
                remarks: data.bookingData.remarks,
                userappliance_id: data.bookingData.userappliance_id,
                bid: data.bookingData.id,
              });

              setBidCalltypeList(data.bookingCharges);
              setBookingUpcountry(data.bookingUpcountry);

              setPurchaseDate(purchase_date);
              setAppointmentDate(appointment_date);
              let modelFreeze = false;
              if (data.spareParts.length > 0) {
                data.spareParts.forEach((item) => {
                  if (item.status !== CANCELLED_STATUS) {
                    modelFreeze = true;
                  }
                });
              }

              setModelFreeze(modelFreeze);
            } else {
              toast.error(
                `${LABEL_JOB} already closed. You can not allow to update booking`
              );
              navigate("/pendingbooking");
            }
          } else {
            toast.error(`${LABEL_JOB} Not Found`);
            navigate("/404");
          }
        } catch (error) {
          navigate("/404");
        }
      }
    })();
  }, [bid]);

  useEffect(() => {
    (async () => {
      if(parentbid){
        try {
          const id = atob(parentbid);
          const data = await httpRequest(
            "getbookingdetails?ishistory=0&id=" + id,
            {},
            "GET"
          );

          console.log(data)

          if (data.status === true) {
            if (
              data.bookingData.sc_closed_date &&
              data.bookingData.closed_date
            ) {
              const purchase_date = new Date(
                data.userApplianceDetails.purchase_date
              );
              setFormData({
                ...formData,
                mobile_number: data.bookingData.booking_primary_mobile_number,
                customer_name: data.bookingData.user_detail.customer_name,
                alternate_mobile_number:
                  data.bookingData.booking_alternate_mobile_number,
                email_id: data.bookingData.user_detail.user_email,
                pincode: data.bookingData.booking_pincode,
                address: data.bookingData.booking_address,
                city: data.bookingData.booking_city,
                state_id: data.bookingData.booking_state,
                user_id: data.bookingData.user_id,
                sellerChannel: {
                  label: data.bookingData.source_channel.source,
                  value: data.bookingData.source_channel.id,
                },
                calltype: {
                  label: "REPEAT BOOKING",
                  value: 7,
                },
                partner_id: data.bookingData.partner_id,
                appliance_id: data.bookingData.appliance_id,
                model_number: data.userApplianceDetails.model_number,
                model_id: data.userApplianceDetails.model_id,
                brand_id: data.userApplianceDetails.brand_id,
                category_id: data.userApplianceDetails.category_id,
                capacity_id: data.userApplianceDetails.capacity_id,
                serial_number: data.userApplianceDetails.serial_number,
                purchase_date: purchase_date,
                order_id: data.bookingData.order_id,
                userappliance_id: data.bookingData.userappliance_id,
              });

              setBookingUpcountry(data.bookingUpcountry);

              setPurchaseDate(purchase_date);
              let modelFreeze = true;

              setModelFreeze(modelFreeze);
            } else {
              toast.error(
                `${LABEL_JOB} already closed. You can not allow to update booking`
              );
              navigate("/pendingbooking");
            }
          } else {
            toast.error(`${LABEL_JOB} Not Found`);
            navigate("/404");
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [parentbid])

  useEffect(() => {

    const fetchData = async () => {
      if (Number(loggedInUser.entity_type) === 1) {
        const partners = await httpRequest(
          "getpartnerlist?is_active=1",
          {},
          "GET"
        );
        if (partners.status === true && partners.data) {
          const { appliance_id, ...rest } = formData;
          setFormData(rest);
          setPartnerlist(partners.data["rows"]);
        }
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          partner_id: loggedInUser.entity_id,
        }));
        // getdropdownList(
        //   setOpAppliance,
        //   `appliancelistmapping?is_active=1&partner_id=${loggedInUser.entity_id}`
        // );
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [loggedInUser]);

  useEffect(() => {
    (async () => {
      let isMobile = isValidMobile(formData.mobile_number);
      if (isMobile) {
        const cdata = await httpRequest(
          `getcustomerdetailsbyphone?mobile_number=${formData.mobile_number}`,
          {},
          "GET"
        );
        if (cdata.status === true && cdata.data) {
          setFormData({
            ...formData,
            customer_name: cdata.data.customer_name,
            alternate_mobile_number: cdata.data.alternate_mobile_number,
            email_id: cdata.data.user_email,
            pincode: cdata.data.pincode,
            address: cdata.data.address,
            city: cdata.data.city,
            state: cdata.data.state,
            user_id: cdata.data.user_id,
            mobile_number: cdata.data.mobile_number,
          });

          getAllCity(cdata.data.pincode);
        }
      }
    })();
  }, [formData.mobile_number]);

  useEffect(() => {
    (async () => {
      getdropdownList(
        setSellerChannelOption,
        `getsellerchannellist?is_active=1`
      );
      getdropdownList(setTypeGroup, `getcalltypegrouplist?is_active=1`);
    })();
    //eslint-disable-next-line
  }, []);

  const getAllCity = async (pincode, callback) => {
    setCityOption([]);

    const listdata = await httpRequest(
      `getAllCity?pincode=${pincode}`,
      {},
      "GET"
    );

    if (listdata.status === true && listdata.message) {
      setCityOption(listdata.message);
      setFormData((prevFormData) => ({
        ...prevFormData,
        state_id: listdata.message[0].state.id,
        pincode,
      }));

      if (listdata.message.length === 1) {
        // setFormData({...formData, city: listdata.message[0].value})
      }

      // Execute the callback after updating city options
      if (callback) {
        callback();
      }
    }
  };

  useEffect(() => {
    if (formData.partner_id) {
      (async () => {
        setOpAppliance([]);
        setBrandList([]);
        setCapacityList([]);
        setCapacityList([]);
        setModelNumberList([]);

        getdropdownList(
          setOpAppliance,
          `appliancelistmapping?is_active=1&partner_id=${formData.partner_id}`
        );
      })();
    }
  }, [formData.partner_id]);

  useEffect(() => {
    (async () => {

      if (formData.partner_id && formData.appliance_id) {
        const listdata = await httpRequest(
          `getmodellist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&isactive=1`,
          {},
          "GET"
        );

        if (listdata.status === true && listdata.data) {
          setModelNumberList(listdata.data["rows"]);
        } else {
          setModelNumberList([]);
          const { model_id, ...newFormData } = formData;
          setFormData(newFormData);
        }

        //   console.log('IN')
        // if (suggestionText.length > 3) {
        //   //     console.log('IN1')
        //   const listdata = await httpRequest(
        //     `getmodellist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&search=${suggestionText}`,
        //     {},
        //     "GET"
        //   );
        //   if (listdata.status === true && listdata.data) {
        //     setModelNumberList(listdata.data["rows"]);
        //   } else {
        //     setModelNumberList([]);
        //     const { model_id, ...newFormData } = formData;
        //     setFormData(newFormData);
        //   }
        // } else if (!suggestionText) {
        //   const { model_number, model_id, ...newFormData } = formData;
        //   setFormData(newFormData);
        //   // setComapnyName("");
        // }
      }
    })();
    //eslint-disable-next-line
  }, [formData.partner_id, formData.appliance_id]);

  useEffect(() => {
    if (!bid) {
      setBrandList([]);
      setCategoryList([]);
      setCapacityList([]);
      setFormData({
        ...formData,
        brand_id: null,
        category_id: null,
        capacity_id: null,
      });
    }

    if (formData.model_id) {
      (async () => {
        const brand = await httpRequest(
          `getbrandlistmapping?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&model_id=${formData.model_id}`,
          {},
          "GET"
        );

        if (brand.status === true && brand.data) {
          if (!bid) {
            const { category_id, capacity_id, ...rest } = formData;
            setFormData(rest);
          }
          setBrandList(brand.data);
          if (brand.data.length == 1) {
            setFormData({ ...formData, brand_id: brand.data[0].value });
          }
        }
      })();
    }
    //eslint-disable-next-line
  }, [formData.model_id]);

  useEffect(() => {
    setCapacityList([]);
    //setFormData({ ...formData, category_id: null, capacity_id: null });
    if (formData.brand_id) {
      (async () => {
        const category = await httpRequest(
          `getcategorylistmapping`,
          {
            partner_id: formData.partner_id,
            appliance_id: formData.appliance_id,
            brand: [{ value: formData.brand_id }],
            model_id: formData.model_id,
          },
          "POST"
        );

        if (category.status === true && category.data) {
          setCategoryList(category.data);
          if (category.data.length == 1) {
            setFormData({ ...formData, category_id: category.data[0].value });
          }
        }
      })();
    }
    //eslint-disable-next-line
  }, [formData.brand_id]);

  useEffect(() => {
    setCapacityList([]);

    if (formData.category_id) {
      (async () => {
        const capacity = await httpRequest(
          `getcapacitylistmapping`,
          {
            partner_id: formData.partner_id,
            appliance_id: formData.appliance_id,
            brand: [{ value: formData.brand_id }],
            category: [{ value: formData.category_id }],
            model_id: formData.model_id,
          },
          "POST"
        );

        if (capacity.status === true && capacity.data) {
          setCapacityList(capacity.data);
          if (capacity.data.length == 1) {
            setFormData({ ...formData, capacity_id: capacity.data[0].value });
          }
        }
      })();
    }
    //eslint-disable-next-line
  }, [formData.category_id]);

  useEffect(() => {
    (async () => {
      setServiceCategory([]);

      if (
        formData.partner_id &&
        formData.appliance_id &&
        formData.brand_id &&
        formData.category_id != null &&
        formData.capacity_id
      ) {
        let postData = {
          partner_id: formData.partner_id,
          appliance_id: formData.appliance_id,
          brand: [{ value: formData.brand_id }],
          category: [{ value: formData.category_id }],
          capacity: [{ value: formData.capacity_id }],
          calltypeGroupID: formData.calltype,
        };
        const data = await httpRequest("filterchargeslist", postData, "POST");

        if (data.status == true && data.data) {
          setServiceCategory(data.data);
        } else {
          setServiceCategory([]);
        }
      }
    })();
  }, [
    formData.partner_id,
    formData.appliance_id,
    formData.calltype,
    formData.brand_id,
    formData.category_id,
    formData.capacity_id,
  ]);

  useEffect(() => {
    if (serviceCategory.length > 0 && bidCalltypeList.length > 0) {
      const initialChecked = serviceCategory.filter((element) => {
        return bidCalltypeList.some((selected) => {
          return (
            String(selected.service_category) === String(element.call_type_id)
          ); // Ensure both are strings
        });
      });

      setServiceChecked(initialChecked);
      setFormData({ ...formData, service_category: initialChecked });

      let upcountry = 0;
      if (
        bookingUpcountry.length > 0 &&
        bookingUpcountry[0].upcountry_paid_by == 2
      ) {
        upcountry =
          bookingUpcountry[0].upcountry_distance *
          bookingUpcountry[0].upcountry_sf_rate;
      }

      let initialTotalCharge = initialChecked.reduce((prev, current) => {
        return prev + parseFloat(current.customer_payable);
      }, 0);

      initialTotalCharge = initialTotalCharge + parseFloat(upcountry);

      setTotalServiceCharge(initialTotalCharge);

      setBidCalltypeList([]);
    }
  }, [serviceCategory, bidCalltypeList, bookingUpcountry]);

  const handleChargesCheckbox = (event, value) => {
    let updatedList = [...serviceChecked];
    if (event.target.checked) {
      updatedList = [...serviceChecked, value];
    } else {
      updatedList = updatedList.filter((item) => item.id !== value.id);
    }

    setServiceChecked(updatedList);
    setFormData({ ...formData, service_category: updatedList });
  };

  useEffect(() => {
    if (formData.service_category && formData.service_category.length > 0) {
      let totalCharge = formData.service_category.reduce((prev, current) => {
        return prev + parseFloat(current.customer_payable);
      }, 0);

      let upcountry = 0;
      if (
        bookingUpcountry.length > 0 &&
        bookingUpcountry[0].upcountry_paid_by == 2
      ) {
        upcountry =
          bookingUpcountry[0].upcountry_distance *
          bookingUpcountry[0].upcountry_sf_rate;
      }

      totalCharge = totalCharge + parseFloat(upcountry);

      setTotalServiceCharge(totalCharge);
    } else {
      setTotalServiceCharge(0);
    }
  }, [formData.service_category, bookingUpcountry]);

  const getUpcountryPaidByPriority = (dataArray) => {
    // Check if any entry has upcountry_paid_by as 2
    if (dataArray.some((item) => item.upcountry_paid_by === 2)) {
      return 2;
    }
    // Check if any entry has upcountry_paid_by as 1
    if (dataArray.some((item) => item.upcountry_paid_by === 1)) {
      return 1;
    }
    // If neither 2 nor 1 are present, return 0
    return 0;
  };

  useEffect(() => {
    if (
      formData.partner_id &&
      formData.appliance_id &&
      formData.pincode &&
      formData.city &&
      formData.state_id &&
      formData.calltype &&
      formData.service_category &&
      formData.service_category.length > 0
    ) {
      (async () => {
        let upcountry_paid_by = getUpcountryPaidByPriority(
          formData.service_category
        );
        if (upcountry_paid_by === 1 || upcountry_paid_by === 2) {
          let postData = {
            partner_id: formData.partner_id,
            appliance_id: formData.appliance_id,
            pincode: formData.pincode,
            city: formData.city,
            state_id: formData.state_id,
            calltype_groupid: formData.calltype.value,
            upcountry_paid_by: upcountry_paid_by,
            assigned_sc_id: formData.assigned_sc_id,
          };

          const upcountry = await httpRequest(
            `checkupcountry`,
            postData,
            "POST"
          );

          if (upcountry.status === true && upcountry.data) {
            setBookingUpcountry(upcountry.data);
          }
        }
      })();
    } else {
      setBookingUpcountry([]);
    }
  }, [
    formData.partner_id,
    formData.appliance_id,
    formData.pincode,
    formData.city,
    formData.state_id,
    formData.calltype,
    formData.service_category,
    formData.assigned_sc_id,
  ]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};

    const requiredFields = [
      { field: "mobile_number", message: "Mobile Number is Required" },
      { field: "customer_name", message: "Customer Name is Required" },
      { field: "pincode", message: "Pincode is Required" },
      { field: "city", message: "City is Required" },
      { field: "sellerChannel", message: "Seller Channel is Required" },
      { field: "calltype", message: "Call Type is Required" },
      { field: "booking_type", message: "Booking Type is Required" },
      { field: "address", message: "Address is Required" },
      { field: "partner_id", message: "Partner is Required" },
      { field: "appliance_id", message: "Appliance Name is Required" },
      { field: "model_id", message: "Model Number is Required" },
      { field: "category_id", message: "Category is Required" },
      { field: "capacity_id", message: "Capacity is Required" },
      { field: "brand_id", message: "Brand is Required" },
      { field: "purchase_date", message: "Purchase Date is Required" },
      { field: "appointment_date", message: "Appointment Date is Required" },
      { field: "service_category", message: "Service Category is Required" },
      { field: "order_id", message: "Order ID is Required" },
      { field: "remarks", message: "Remarks is Required" },
    ];

    requiredFields.forEach(({ field, message }) => {
      if (!value[field]) {
        errors[field] = message;
      }
    });

    if (value.mobile_number) {
      if (!isValidMobile(value.mobile_number)) {
        errors.mobile_number = "Enter Valid Mobile Number";
      }
    }

    if (
      value.alternate_mobile_number &&
      !isValidMobile(value.alternate_mobile_number)
    ) {
      errors.alternate_mobile_number = "Enter Valid Alternate Mobile Number";
    }

    if (value.email_id && !isValidEmail(value.email_id)) {
      errors.email_id = "Enter Valid Email ID";
    }

    if (value.pincode && value.pincode.length !== 6) {
      errors.pincode = "Enter Valid Pincode";
    }

    if (!value.service_category) {
      errors.service_category = "Service Category is Manadatory";
    } else if (value.service_category.length < 1) {
      errors.service_category = "Service Category is Manadatory";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let postData = formData;
        if (formData.invoice_file) {
          const invoice_file = await UploadFileToS3(
            "docFile",
            formData.invoice_file
          );
          postData.invoice_file = invoice_file;
        }

        if (formData.warranty_file) {
          const warranty_file = await UploadFileToS3(
            "docFile",
            formData.warranty_file
          );
          postData.warranty_file = warranty_file;
        }

        if (formData.serial_number_file) {
          const serial_number_file = await UploadFileToS3(
            "docFile",
            formData.serial_number_file
          );
          postData.serial_number_file = serial_number_file;
        }

        document.getElementById("addbasicdetails").reset();

        const data = await httpRequest(
          "processcreatebooking",
          postData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          navigate("/pendingbooking");
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const getMinDate = () => {
    const now = new Date();
    const twoPM = new Date();
    twoPM.setHours(14, 0, 0, 0); // Set to 2 PM today

    // If current time is after 2 PM, set minDate to tomorrow
    if (now > twoPM) {
      return new Date(now.setDate(now.getDate() + 1));
    }
    // If before 2 PM, set minDate to today
    return new Date();
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle={
          formData.booking_id ? `Update ${LABEL_JOB}` : "Create Booking"
        }
        breadcrumbSubTitle={formData.booking_id ? formData.booking_id : ""}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <form id="addbasicdetails" onSubmit={onSubmitHandler}>
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Customer Details</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Mobile *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.mobile_number}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.mobile_number}
                              name="mobile_number"
                              placeholder="Enter Mobile Number"
                              id="mobile_number"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  mobile_number: e.target.value,
                                });
                                // let isMobile = isValidMobile(e.target.value);
                                // if (isMobile) {
                                //   getCustomerDetails(e.target.value);
                                // }
                              }}
                            />
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Full Name *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.customer_name}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="customer_name"
                              placeholder="Enter Customer Name"
                              id="customer_name"
                              defaultValue={formData.customer_name}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {/* /.form-group */}
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Alternate Mobile Number{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.alternate_mobile_number}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="alternate_mobile_number"
                              placeholder="Enter Alternate Mobile Number"
                              id="alternate_mobile_number"
                              defaultValue={formData.alternate_mobile_number}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Email{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.email_id}
                              </span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email_id"
                              placeholder="Enter Email"
                              id="email_id"
                              defaultValue={formData.email_id}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Pincode *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.pincode}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pincode"
                              placeholder="Enter Pincode"
                              id="pincode"
                              defaultValue={formData.pincode}
                              onChange={(e) => {
                                if (e.target.value.length === 6) {
                                  getAllCity(e.target.value);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              City *{" "}
                              {cityOption.length === 0 &&
                                formData.pincode &&
                                "Loading..."}{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.city}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="city"
                                placeholder="Select City"
                                value={
                                  cityOption.find(
                                    (option) => option.value === formData.city
                                  ) || null
                                }
                                options={cityOption}
                                onChange={(val) => {
                                  setFormData({ ...formData, city: val.value });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Address *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.address}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="address"
                                id="address"
                                placeholder="Enter Address"
                                className="form-control"
                                defaultValue={formData.address}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Seller Channel *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.sellerChannel}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                value={formData.sellerChannel}
                                name="seller_channel"
                                placeholder="Select Seller Channel"
                                options={sellerChannelOption}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    sellerChannel: val,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Call Type *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.calltype}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                defaultValue={formData.calltype}
                                value={formData.calltype}
                                isDisabled={parentbid ? Number(loggedInUser.entity_type) === 1 ? false: true : modelFreeze}
                                name="calltypeggroup_id"
                                placeholder="Select Call Type"
                                options={typeGroupoptions}
                                onChange={(val) => {
                                  setFormData({ ...formData, calltype: val });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>
                        <div className="col-md-4 " style={{ display: "none" }}>
                          <div className="form-group text-center">
                            <label>
                              Booking Type *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.booking_type}
                              </span>
                            </label>
                            <div className="form-group clearfix ">
                              <div className="icheck-primary d-inline form-control">
                                <input
                                  type="radio"
                                  id="radioPrimary1"
                                  value={"Enquiry"}
                                  name="booking_type"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "on" ||
                                      e.target.value === "Enquiry"
                                    ) {
                                      setFormData({
                                        ...formData,
                                        booking_type: "Enquiry",
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="radioPrimary1">Enquiry</label>
                              </div>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="icheck-primary d-inline form-control">
                                <input
                                  type="radio"
                                  id="radioPrimary2"
                                  value={"Booking"}
                                  name="booking_type"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "on" ||
                                      e.target.value === "Booking"
                                    ) {
                                      setFormData({
                                        ...formData,
                                        booking_type: "Booking",
                                      });
                                    }
                                  }}
                                  defaultChecked
                                />
                                <label htmlFor="radioPrimary2">Booking</label>
                              </div>
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Appliance Details</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        {Number(loggedInUser.entity_type) === 1 && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Partner *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.partner_id}
                                </span>
                              </label>
                              <Select
                                name="partner_id"
                                value={
                                  partnerlist.find(
                                    (option) =>
                                      option.value === formData.partner_id
                                  ) || null
                                }
                                options={partnerlist}
                                isDisabled={parentbid ? true :modelFreeze}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    partner_id: val.value,
                                    appliance_id: null,
                                    brand_id: null,
                                    category_id: null,
                                    capacity_id: null,
                                    model_id: null,
                                    model_number: "",
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Appliance *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.appliance_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="appliance_id"
                                placeholder="Select Appliance"
                                value={
                                  applianceOption.find(
                                    (option) =>
                                      option.value === formData.appliance_id
                                  ) || null
                                }
                                options={applianceOption}
                                isDisabled={parentbid ? true :modelFreeze}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    appliance_id: val.value,
                                    model_number: "",
                                    brand_id: null,
                                    category_id: null,
                                    capacity_id: null,
                                  });

                                  setModelNumberList([]);
                                  setBrandList([]);
                                  setCategoryList([]);
                                  setCapacityList([]);
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Model Number *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.model_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="model_id"
                                placeholder="Select Model Number"
                                isDisabled={parentbid ? true : false}
                                value={
                                  modelNumberList.find(
                                    (option) =>
                                      option.value === formData.model_id
                                  ) || null
                                }
                                options={modelNumberList}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    model_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Brand *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.brand_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="brand_id"
                                placeholder="Select Brand"
                                value={
                                  brandList.find(
                                    (option) =>
                                      option.value === formData.brand_id
                                  ) || null
                                }
                                options={brandList}
                                isDisabled={parentbid ? true : false}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    brand_id: val.value,
                                    capacity_id: null,
                                    category_id: null,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Category *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.category_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="category_id"
                                placeholder="Select Category"
                                value={
                                  categoryList.find(
                                    (option) =>
                                      option.value === formData.category_id
                                  ) || null
                                }
                                options={categoryList}
                                isDisabled={parentbid ? true : false}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    category_id: val.value,
                                    capacity_id: null,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Capacity *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.capacity_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="capacity_id"
                                placeholder="Select Capacity"
                                isDisabled={parentbid ? true : false}
                                value={
                                  capacityList.find(
                                    (option) =>
                                      option.value === formData.capacity_id
                                  ) || null
                                }
                                options={capacityList}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    capacity_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Serial Number{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.serial_number}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="serial_number"
                              placeholder="Enter Serial Number"
                              id="serial_number"
                              defaultValue={formData.serial_number}
                              value={formData.serial_number}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Purchase Date *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.purchase_date}
                              </span>
                            </label>

                            <DatePicker
                              maxDate={new Date()}
                              selected={purchaseDate}
                              placeholderText="Select Purchase Date"
                              dateFormat="dd/MM/yyyy"
                              readOnly={parentbid ? true : false}
                              className="form-control"
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  purchase_date: date,
                                });
                                setPurchaseDate(date);
                              }}
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                        </button> */}
                                  <select
                                    style={{
                                      marginRight: 3,
                                    }}
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                        </button> */}
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Appointment Date *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.appointment_date}
                              </span>
                            </label>
                            <DatePicker
                              className="form-control text-capitalize"
                              minDate={getMinDate()}
                              dateFormat="dd/MM/yyyy"
                              selected={appointmentDate}
                              placeholderText="Select Appointment Date"
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  appointment_date: date,
                                });
                                setAppointmentDate(date);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Reference / Invoice / Order Number *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.order_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="order_id"
                                value={formData.order_id}
                                id="order_id"
                                placeholder="Enter Reference / Invoice / Order Number"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                                onBlur={(e) => {
                                  // checkDuplicateOrder(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-8">
                          <div className="form-group">
                            <label>
                              Remarks *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.remarks}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="remarks"
                                id="remarks"
                                value={formData.remarks}
                                placeholder="Enter remarks"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Service Category</h3>
                      <div
                        className="card-tools"
                        style={{ color: "red", marginRight: "40px" }}
                      >
                        <b>{formErrors.service_category}</b>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <table className="table table-bordered  table-hover table-striped data dataTable no-footer ">
                          <thead>
                            <tr>
                              <th>SNo.</th>
                              <th>Service Category</th>
                              <th>Customer Charges</th>
                              <th>Select Services</th>
                            </tr>
                          </thead>
                          <tbody>
                            {serviceCategory.map((element, index) => {
                              const isChecked = serviceChecked.some(
                                (item) => item.id === element.id
                              );
                              return (
                                <tr
                                  key={index}
                                  id={"call_" + index}
                                  className="text-center"
                                >
                                  <td>{index + 1}</td>
                                  <td>{element.call_type}</td>
                                  <td>{element.customer_payable}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="form-control charges_checkbox text-capitalize"
                                      name="charges_checkbox"
                                      id={"charges_checkbox" + index}
                                      value={element.id}
                                      checked={isChecked}
                                      onChange={(e) =>
                                        handleChargesCheckbox(e, element)
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                            {bookingUpcountry.length > 0 &&
                              bookingUpcountry[0].is_upcountry == 1 && (
                                <tr
                                  key={"upcountry"}
                                  id={"call_upcountry"}
                                  className="text-center"
                                >
                                  <td>{serviceCategory.length + 1}</td>
                                  <td>Upcountry</td>
                                  <td>
                                    {bookingUpcountry[0].upcountry_paid_by == 2
                                      ? bookingUpcountry[0].upcountry_sf_rate *
                                        bookingUpcountry[0].upcountry_distance
                                      : 0}
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="form-control charges_checkbox text-capitalize"
                                      name="charges_checkbox"
                                      id={"charges_checkbox_upcountry"}
                                      value={"upcountry"}
                                      checked={true}
                                    />
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Total Customer Charge {" Incl GST"}</label>
                            <input
                              type="text"
                              className="form-control text-capitalize"
                              name="total_service_charge"
                              placeholder="Total Service Charge"
                              id="total_service_charge"
                              value={totalServiceCharge}
                              readOnly="readonly"
                            />
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">File Upload</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Invoice File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.invoice_file}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="invoice_file"
                                id="invoice_file"
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    invoice_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Warranty File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.warranty_file}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="warranty_file"
                                id="warranty_file"
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    warranty_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Serial Number File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.serial_number_file}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="serial_number_file"
                                id="serial_number_file"
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    serial_number_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card-footer text-center"
                    style={{ marginBottom: "60px" }}
                  >
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary col-md-6"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default CreateBooking;
