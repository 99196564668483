import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MainBody from "../../../../component/MainBody";
import MyDataTable from "../../../../component/MyDataTable";
import Switch from "../../../../component/Switch";
import Select from "react-select";
import httpRequest from "../../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CallType = () => {
  const [reloadList, setReload] = useState(true);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isOpenForm, setOpenForm] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [options, setOptions] = useState([]);
  const [typeGroupoptions, setTypeGroup] = useState([]);
  const [poptions] = useState([
    { value: "Service", label: "Service" },
    { value: "Product", label: "Product" }
  ]);
  const warrantyoptions = [
    { label: "In-Warranty", value: 1 },
    { label: "Out Of Warranty", value: 2 },
    { label: "Extended Warranty", value: 3 },
    { label: "Presale", value: 4 }
  ];

  useEffect(() => {
    (async () => {
      const listdata = await httpRequest(
        "getappliancelist?isactive=1",
        {},
        "GET"
      );
      if (listdata.status === true && listdata.data) {
        setOptions(listdata.data["rows"]);
      }

      const typedata = await httpRequest(
        "getcalltypegrouplist?is_active=all",
        {},
        "GET"
      );
      if (typedata.status === true && typedata.data) {
        setTypeGroup(typedata.data["rows"]);
      }
    })();
  }, []);

  const updateHandler = (row) => {
    const array = JSON.parse(row.allowed_warranty_status);
    console.log(array[0])
    setFormData({ ...formData, id: row.id, 
        appliance_name: row.appliance['appliance_name'],
        calltype_group: row.calltype_group['type'],
        is_product: row.is_product, appliance_id: row.appliance_id,
        call_type: row.call_type, calltype_group_id: row.calltype_group_id,
        allowed_warranty_status: [array]
     });
    setOpenForm(true);
  };

  const getA = (row) => {
    try {
      if (row.allowed_warranty_status) {
        //console.log(row.allowed_warranty_status);
  
        // Attempt to parse the JSON string
        const array = JSON.parse(row.allowed_warranty_status);
  
        // Ensure the parsed value is an array
        if (Array.isArray(array)) {
          return array.map((item) => item.label || "").join(", ");
        } else {
         // console.warn("Parsed value is not an array:", array);
          return "";
        }
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error parsing allowed_warranty_status:", error);
      return "";
    }
  };
  

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Product/Service",
      selector: (row) => row.is_product,
    },
    {
      name: "Appliance",
      selector: (row) => row.appliance['appliance_name'],
    },
    {
      name: "Call Type Group",
      selector: (row) => row.calltype_group['type'],
    },
    {
      name: "Call Type",
      selector: (row) => row.call_type,
      cell: (row) => <div className="word-wrap">{row.call_type}</div>,
    },
    {
      name: "Allowed Warranty Status",
      selector: (row) => getA(row),
      cell: (row) => <div className="word-wrap">{getA(row)}</div>,
    },
    {
      name: 'Spare Allowed',
      selector: (row) => (Number(row.calltype_group?.is_part_order) === 1 ? "YES" : "NO"),
    },
    {
        name: "Status",
        selector: (row) =>
        Number(row.is_active) === 1 ? "Activated" : "DeActivated",
        cell: (row) => (
          <Switch
            isOn={Number(row.is_active) === 1 ? true : false}
            id={row.id}
            handleToggle={(e) => {
              updateToggleHandler(row.id, e.target.checked);
            }}
            onColor="#EF476F"
          />
        ),
      },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
    {
      name: "Update",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            updateHandler(row);
          }}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  const updateToggleHandler = async (id, value) => {
    try {
      setReload(false);
      let postData = {
        id,
        is_active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updatecalltypestatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating the status.");
    }
  };

  const closeForm = () => {
    setOpenForm(false);
  };

  const validateForm = (value) => {
    const errors = {};
    if (!value.appliance_id) {
        errors.appliance_id = "Appliance is required";
    }

    if (!value.call_type) {
      errors.call_type = "Call Type is required";
    }

    if (!value.is_product) {
      errors.is_product = "Product/Service is required";
    }

    if (!value.calltype_group_id)
    {
      errors.calltype_group_id = "Call Type Group is required";
    }

    if (!value.allowed_warranty_status)
    {
      errors.allowed_warranty_status = "Allowed Warranty Status is required";
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit)
        {
          setSubmit(false);
          (async () => {
            const data = await httpRequest("addupdatecalltype", formData, "POST");
    
          if (data.status === true && data.message) {
              
              setReload(true);
    
              toast.success(data.message);
              setFormData({});
              closeForm();
            } else {
              toast.success(data.message);
            }
          })();
        }
        //eslint-disable-next-line

  }, [formErrors, isSubmit])

  const formAddUpdate = () => {
    return (
      <div className="form-overlay">
        <div className="form-container">
          <div className="form-content">
            <form id="assignPartner" onSubmit={onSubmitHandler}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" style={{ marginBottom: "0px" }}>
                    <div className="card-header">
                      <h3 className="card-title">
                        {formData.id ? "Update Call Type" : "Add Call Type"}
                      </h3>
                      <button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={closeForm}
                        style={{ float: "right" }}
                      >
                        X
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Appliance Name *
                              <span style={{ color: "red" }}>
                                {formErrors.appliance_id}
                              </span>
                            </label>
                            <Select
                              defaultValue={formData.appliance_name ? { label: formData.appliance_name, value: formData.appliance_id } : undefined} 
                              name="appliance_id"
                              options={options}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  appliance_id: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Call Type Group *
                              <span style={{ color: "red" }}>
                                {formErrors.calltype_group_id}
                              </span>
                            </label>
                            <Select
                             defaultValue={formData.calltype_group ? { label: formData.calltype_group, value: formData.calltype_group_id } : undefined} 
                              name="calltype_group_id"
                              options={typeGroupoptions}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  calltype_group_id: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Call Type *
                              <span style={{ color: "red" }}>
                                {formErrors.call_type}
                              </span>
                            </label>
                            <input
                              type="text"
                              value={formData.call_type || ""}
                              name="call_type"
                              className={
                                !formErrors.call_type
                                  ? "form-control"
                                  : "is-invalid form-control"
                              }
                              placeholder="Enter Call Type"
                              defaultValue={formData.call_type}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value
                                    .replace(/['"]+/g, "")
                                    .toUpperCase(),
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Product/Service *
                              <span style={{ color: "red" }}>
                                {formErrors.is_product}
                              </span>
                            </label>
                            <Select
                              defaultValue={formData.is_product ? { label: formData.is_product, value: formData.is_product } : undefined} 
                              name="is_product"
                              options={poptions}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  is_product: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Allowed Warranty Status *
                              <span style={{ color: "red" }}>
                                {formErrors.allowed_warranty_status}
                              </span>
                            </label>
                            <Select
                              defaultValue={formData.allowed_warranty_status ? formData.allowed_warranty_status[0] : undefined} 
                              isMulti={true}
                              name="allowed_warranty_status"
                              options={warrantyoptions}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  allowed_warranty_status: val,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-center"
                      style={{
                        marginBottom: "60px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary col-md-12"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle="Call Type"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Call Type List</h3>
                        <Link
                          className="btn btn-sm btn-primary float-right"
                          to={"#"}
                          onClick={() => {
                            setOpenForm(true);
                            setFormData({});
                          }}
                        >
                          Add New CallType
                        </Link>
                      </div>
                      <div className="card-body">
                        <MyDataTable
                          reloadList={reloadList}
                          columns={columns}
                          getDataListURL={"getcalltypelist?isactive=all"}
                          setReload={setReload}
                          exportName={"calltypelist"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isOpenForm && formAddUpdate()}
            </section>
          </>
        }
      />
    </div>
  );
};

export default CallType;
