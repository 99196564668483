import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import httpRequest from "../../utils/Http";
import { toast } from "react-toastify";
import MainBody from "../../component/MainBody";
import { LABEL_JOB } from "../../constant/label_constant";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { UploadFileToS3 } from "../../utils/S3File";

const PartOrderRequest = () => {
  const navigate = useNavigate();
  const { bid, spare_id } = useParams();
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [partTypelist, setPartTypelist] = useState([]);
  const [partNameList, setPartNameList] = useState([]);
  const [partWarrantyList, setpartWarrantyList] = useState([]);
  const [quantitylist, setQuantityList] = useState([]);
  const [files, setFiles] = useState([]);


  // useEffect(() => {
  //   console.log(formData)

  // }, [formData])

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) =>
      prevFiles.concat(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    );
    setFormErrors({ ...formErrors, documentTypeId: "" });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*, application/*, text/*, video/*",
  });

  const validateForm = (value) => {
    const errors = {};

    if(!value.part_type){
        errors.part_type = "Part Type is mandatory";
    }

    if(!value.part_id){
        errors.part_id = "Part Name is mandatory";
    }

    if(!value.part_warranty){
        errors.part_warranty = "Part Warranty is mandatory";
    }

    if(!value.quantity){
        errors.quantity = "Quantity is mandatory";
    }

    if(!value.remarks){
        errors.remarks = "Problem Description is mandatory";
    }

    if(!value.spare_id){
      if (files.length === 0) {
        errors.files = "Select at least one file";
      }
    }
   
    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    const submitData = async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const formDataToSubmit = { ...formData };
        if (files.length > 0) {
          window["showloadingmodel"]();

          const uploadPromises = files.map((file) =>
            UploadFileToS3("docFile", file)
          );
          const fileUrls = await Promise.all(uploadPromises);

          const dataToSave = files.map((file, index) => ({
            docFileurl: fileUrls[index],
            docName: file.name,
          }));

          formDataToSubmit.documentFile = JSON.stringify(dataToSave);

          setFormData(formDataToSubmit);
          window["distroyloadingmodel"]();
        }

        const res = await httpRequest(
          "processrequestpartorder",
          formDataToSubmit,
          "POST",
          "multipart/form-data"
        );

        if (res.status) {
          setFiles([]);
          toast.success(res.message);
          navigate('/pendingbooking');
        } else {
          toast.error(res.message);
        }
        setSubmit(false);
      }
    };
    submitData();
  }, [isSubmit, formErrors]);

  useEffect(() => {
    (async () => {
      if (bid) {
        try {
          const id = atob(bid);
          const data = await httpRequest(
            "getbookingdetails?ishistory=0&id=" + id,
            {},
            "GET"
          );

          if (data.status == true) {
            if (
              !data.bookingData.sc_closed_date &&
              !data.bookingData.closed_date
            ) {
              if (data.bookingData.calltype_group.is_part_order === true) {
                const sf_purchase_date = data.userApplianceDetails
                  .sf_purchase_date
                  ? new Date(data.userApplianceDetails.sf_purchase_date)
                  : "";

                let part_warranty = 1;

                data.bookingCharges.map((item) => {
                 // console.log(item.call_type.allowed_warranty_status)
                  let parse = JSON.parse(
                    item.call_type.allowed_warranty_status
                  );
                //  console.log(parse)
                  if(Array.isArray(parse)){
                    setpartWarrantyList(parse);
                  } else {
                    parse = JSON.parse(parse)
                     setpartWarrantyList(parse);
                  }
                  
                  for (const item1 of parse) {
                    //console.log(item1)
                    if (Number(item1.value) === 2) {
                      part_warranty = 2;
                    }
                  }
                });

                setFormData({
                  ...formData,
                  booking_id: data.bookingData.booking_id,
                  partner_id: data.bookingData.partner_id,
                  calltype: {
                    label: data.bookingData.calltype_group.type,
                    value: data.bookingData.calltype_group.id,
                  },
                  appliance_id: data.bookingData.appliance_id,
                  model_number: data.userApplianceDetails.model_number,
                  model_id: data.userApplianceDetails.model_id,
                  sf_serial_number: data.userApplianceDetails.sf_serial_number,
                  sf_purchase_date: sf_purchase_date,
                  sf_invoice_id: data.bookingData.sf_invoice_id,
                  bid: data.bookingData.id,
                  sf_serial_number_file:
                    data.userApplianceDetails.sf_serial_number_file,
                  sf_invoice_file: data.userApplianceDetails.invoice_file,
                  part_warranty: part_warranty,
                });

                if(spare_id){
                  (async() => {
                    try {
                      const spareId = atob(spare_id)
                      const spare_response = await httpRequest(`getsparedetailslist?id=${spareId}`, {}, "GET");
                      if(spare_response.status && spare_response.data){
                          const data = spare_response.data['rows'][0];
                          // Set initial formData first
                          const initialFormData = {
                            spare_id: data.id,
                            part_type: data.requested_part.part_type,
                            part_id: data.requested_part_id,
                            part_warranty: data.warranty_status,
                            quantity: data.requested_quantity,
                            remarks: data.problem_description
                          };
                          setFormData((prevData) => ({
                            ...prevData,
                            ...initialFormData,
                          }));
          
            
                          // Fetch part name list
                          const partNameListResponse = await httpRequest(
                            `getbompartnamelist?partner_id=${data.booking_detail.partner_id}&appliance_id=${data.booking_detail.appliance_id}&model_id=${data.requested_model_id}&part_type=${data.requested_part.part_type}`,
                            {},
                            "GET"
                          );
                          if (partNameListResponse.status === true && partNameListResponse.data) {
                            setPartNameList(partNameListResponse.data);
                          }
            
                          // // Fetch quantity list
                          const quantityListResponse = await httpRequest(
                            `getbommaxqauntity?model_id=${data.requested_model_id}&part_id=${data.requested_part_id}`,
                            {},
                            "GET"
                          );
                          if (quantityListResponse.status === true && quantityListResponse.data) {
                            setQuantityList(quantityListResponse.data);
                          }
                      }
                    } catch (error) {
                      console.log(error)
                      // toast.error(
                      //   `Invalid Url`
                      // );
                      // navigate('/pendingbooking')
                    }
                  })();
                }

              } else {
                toast.error(
                  `We are not allowing to request part for the attatched calltype on this ${LABEL_JOB}`
                );
                navigate("/pendingbooking");
              }
            } else {
              toast.error(
                `${LABEL_JOB} already closed. You can not allow to update booking`
              );
              navigate("/pendingbooking");
            }
          } else {
            console.log(".....");
            toast.error(`${LABEL_JOB} Not Found`);
            navigate("/404");
          }
        } catch (error) {
          console.log(".....");
          navigate("/404");
        }
      }
    })();
  }, [bid, spare_id]);
  

  useEffect(() => {
    (async () => {
      if (formData.appliance_id && formData.partner_id && formData.model_id) {
        const partTypelist = await httpRequest(
          `getbomparttypelist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&model_id=${formData.model_id}`,
          {},
          "GET"
        );

        if (partTypelist.status === true && partTypelist.data) {
          setPartTypelist(partTypelist.data);
        }
      }
    })();
  }, [formData.partner_id, formData.model_id, formData.appliance_id]);

  useEffect(() => {
    (async () => {
      if (
        formData.appliance_id &&
        formData.partner_id &&
        formData.model_id &&
        formData.part_type
      ) {
        const { part_name, quantity, part_warranty, ...rest } = formData;
        setFormData(rest);
        const partTypelist = await httpRequest(
          `getbompartnamelist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&model_id=${formData.model_id}&part_type=${formData.part_type}`,
          {},
          "GET"
        );

        if (partTypelist.status === true && partTypelist.data) {
          setPartNameList(partTypelist.data);
        }
      }
    })();
  }, [
    formData.partner_id,
    formData.model_id,
    formData.appliance_id,
    formData.part_type,
  ]);

  useEffect(() => {
    (async () => {
      if (formData.model_id && formData.part_id) {
        const partTypelist = await httpRequest(
          `getbommaxqauntity?model_id=${formData.model_id}&part_id=${formData.part_id}`,
          {},
          "GET"
        );

        if (partTypelist.status === true && partTypelist.data) {
          setQuantityList(partTypelist.data);
        }
      }
    })();
  }, [formData.model_id, formData.part_id]);

  return (
    <div>
      <MainBody
        breadcrumbTitle={"Request New Part"}
        breadcrumbSubTitle={formData.booking_id ? formData.booking_id : ""}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <form id="addbasicdetails" onSubmit={onSubmitHandler}>
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Part Order</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Part Type *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_type}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                value={
                                  partTypelist.find(
                                    (option) =>
                                      option.value === formData.part_type
                                  ) || null
                                }
                                name="part_type"
                                placeholder="Select Part Type"
                                options={partTypelist}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    part_type: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Part Name *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                value={
                                  partNameList.find(
                                    (option) =>
                                      option.value === formData.part_id
                                  ) || null
                                }
                                name="part_id"
                                placeholder="Select Part Name"
                                options={partNameList}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    part_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Part Warranty *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_warranty}
                              </span>
                            </label>
                            <div className="select2-purple">
                            <Select
                                value={
                                  (Array.isArray(partWarrantyList)
                                    ? partWarrantyList.find(
                                        (option) => option.value === formData.part_warranty
                                      )
                                    : null) || null
                                }
                                name="part_warranty"
                                placeholder="Select Part Warranty"
                                options={ Array.isArray(partWarrantyList) ? partWarrantyList.map((item) => ({
                                  label: item.label,
                                  value: item.value,
                                })) : []}
                                onChange={(val) =>
                                  setFormData({ ...formData, part_warranty: val.value })
                                }
                              />

                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Quantity *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.quantity}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                value={
                                  quantitylist.find(
                                    (option) =>
                                      option.value === formData.quantity
                                  ) || null
                                }
                                name="quantity"
                                placeholder="Select Quantity"
                                options={quantitylist}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    quantity: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="form-group">
                            <label>
                              Problem Description *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.remarks}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                placeholder="Enter Problem Description"
                                value={formData.remarks}
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    remarks: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                        <label>
                              Defective Picture / Video *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.files}
                              </span>
                            </label>
                          <div {...getRootProps()} style={dropzoneStyles}>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                          <div>
                            <br />
                            <span style={{ color: "red" }}>
                              {formErrors.documentTypeId}
                            </span>
                            {files.length > 0 && (
                              <>
                                <br />
                                <br />
                                <table style={tableStyles}>
                                  <thead>
                                    <tr>
                                      <th>File Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {files.map((file) => (
                                      <tr
                                        key={file.name}
                                        style={tableRowStyles}
                                      >
                                        <td>{file.name}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-footer text-center"
                    style={{ marginBottom: "60px" }}
                  >
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary col-md-6"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

const tableStyles = {
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "10px",
};

const tableRowStyles = {
  borderBottom: "1px solid #dddddd",
};

export default PartOrderRequest;
