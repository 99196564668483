import React, { useEffect, useState } from "react";
import MainBody from "../../component/MainBody";
import httpRequest from "../../utils/Http";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getdropdownList } from "../../utils/HelperFunction";
import {
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  PARTNER_TYPE,
} from "../../constant/status_constant";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [stateList, setStateList] = useState([]);
  const [opentatFilter, setOpentatFilter] = useState(false);
  const [closedtatFilter, setClosedtatFilter] = useState(false);
  const [partnerlist, setPartnerlist] = useState([]);
  const [appliancelist, setApplianceList] = useState([]);
  const [calltypelist, setCalltypeList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({tabwise: 1});
  const [OpenformData, setOpenFormData] = useState({tabwise: 1});
  const [reloadList, setReload] = useState(false);
  const [openReloadList, setOpenReloadList] = useState(false);
  const [CompletedTatData, setCompletedTatData] = useState([]);
  const [openTatData, setOpenTatData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [startOpenDate, setOpenStartDate] = useState();
  const [openEndDate, setOpenEndDate] = useState();

  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [chartTotal, setChartTotal] = useState({
    totalD0: 0,
    totalD1: 0,
    totalD2: 0,
    totalD3: 0,
    totalD4: 0,
    totalD5: 0,
    totalD6: 0,
    totalD6plus: 0,
    totalD: 0,
  });

  const [openChartTotal, setOpenChartTotal] = useState({
    totalD0: 0,
    totalD1: 0,
    totalD2: 0,
    totalD3: 0,
    totalD4: 0,
    totalD5: 0,
    totalD6: 0,
    totalD6plus: 0,
    totalD: 0,
  });

  const [dashboardNumber, setDashboardNumber] = useState({
    completedCount: 0,
    cancelledCount: 0,
    newRegisteredCount: 0,
    pendingCount: 0,
  });

  const isSpareList = [
    { label: "With Spare", value: 1 },
    { label: "Without Spare", value: 0 },
  ];

  const isUpcountryList = [
    { label: "Local", value: 0 },
    { label: "Upcountry", value: 1 },
  ];

  const closedStatusList = [
    { label: "COMPLETED", value: COMPLETED_STATUS },
    { label: "CANCELLED", value: CANCELLED_STATUS },
  ];

  useEffect(() => {
    // This effect runs once when the component mounts to set the default date range
    //setStartDate(moment().subtract(30, "days").toDate());
    // setEndDate(new Date());

    let partner_id = null;

    if (Number(loggedInUser.entity_type) === PARTNER_TYPE) {
      partner_id = loggedInUser.entity_id;
    }

    setFormData({
      ...formData,
      //start_date: moment().subtract(30, "days").toDate(),
      //end_date: new Date(),
      current_status: COMPLETED_STATUS,
      partner_id,
    });

    setReload(true);
  }, []);

  useEffect(() => {
    // This effect runs once when the component mounts to set the default date range
    //setOpenStartDate(moment().subtract(30, "days").toDate());
    // setOpenEndDate(new Date());

    let partner_id = null;

    if (Number(loggedInUser.entity_type) === PARTNER_TYPE) {
      partner_id = loggedInUser.entity_id;
    }

    setOpenFormData({
      ...OpenformData,
      // start_date: moment().subtract(30, "days").toDate(),
      // end_date: new Date(),
      partner_id,
    });

    setOpenReloadList(true);
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData({
      ...formData,
      start_date: date,
    });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData({
      ...formData,
      end_date: date,
    });
  };

  const handleOpenEndDateChange = (date) => {
    setOpenEndDate(date);
    setOpenFormData({
      ...OpenformData,
      end_date: date,
    });
  };

  const handleOpenStartDateChange = (date) => {
    setOpenStartDate(date);
    setOpenFormData({
      ...OpenformData,
      start_date: date,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (reloadList === true && formData) {
        setReload(false);
        try {
          const completedtat = await httpRequest(
            "getcompletedtatreport",
            formData,
            "POST"
          );
          if (completedtat.status === true && completedtat.data) {
            setCompletedTatData(completedtat.data);
          } else {
            setCompletedTatData([]);
            setChartTotal({
              totalD0: 0,
              totalD1: 0,
              totalD2: 0,
              totalD3: 0,
              totalD4: 0,
              totalD5: 0,
              totalD6: 0,
              totalD6plus: 0,
              totalD: 0,
            });
          }
        } catch (error) {
          console.error("Error fetching completed TAT report:", error);
          setCompletedTatData([]);
          setChartTotal({
            totalD0: 0,
            totalD1: 0,
            totalD2: 0,
            totalD3: 0,
            totalD4: 0,
            totalD5: 0,
            totalD6: 0,
            totalD6plus: 0,
            totalD: 0,
          });
        }
      }
    };

    fetchData();
  }, [formData, reloadList]);

  useEffect(() => {
    const fetchData = async () => {
      if (openReloadList === true && OpenformData) {
        setOpenReloadList(false);
        try {
          const completedtat = await httpRequest(
            "getopentatreport",
            OpenformData,
            "POST"
          );
          if (completedtat.status === true && completedtat.data) {
            setOpenTatData(completedtat.data);
          } else {
            setOpenTatData([]);
            setOpenChartTotal({
              totalD0: 0,
              totalD1: 0,
              totalD2: 0,
              totalD3: 0,
              totalD4: 0,
              totalD5: 0,
              totalD6: 0,
              totalD6plus: 0,
              totalD: 0,
            });
          }
        } catch (error) {
          console.error("Error fetching completed TAT report:", error);
          setOpenTatData([]);
          setOpenChartTotal({
            totalD0: 0,
            totalD1: 0,
            totalD2: 0,
            totalD3: 0,
            totalD4: 0,
            totalD5: 0,
            totalD6: 0,
            totalD6plus: 0,
            totalD: 0,
          });
        }
      }
    };

    fetchData();
  }, [openReloadList, OpenformData]);

  useEffect(() => {
    (async () => {
      const state = await httpRequest("getallstatelist", {}, "GET");
      //console.log(partners);
      if (state.status === true && state.message) {
        setStateList(state.message);
      } else {
        setStateList([]);
      }

      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );
      //console.log(partners);
      if (partners.status === true && partners.data) {
        setPartnerlist(partners.data["rows"]);
      }
      const appliance = await httpRequest(
        "getappliancelist?isactive=1",
        {},
        "GET"
      );
      //console.log(partners);
      if (appliance.status === true && appliance.data) {
        setApplianceList(appliance.data["rows"]);
      }

      getdropdownList(setCalltypeList, "getcalltypegrouplist?is_active=1");
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpRequest(
          "getlast12MonthCompletedBookings",
          {},
          "GET"
        );
        if (response.status === true && response.data) {
          setChartData(response.data);
          console.log(response.data);
        }

        const dashboardResponse = await httpRequest(
          "getSummaryDashboardNumber",
          {},
          "GET"
        );
        if (dashboardResponse && dashboardResponse.status === true) {
          setDashboardNumber(dashboardResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const exportToExcel = () => {
    const dataToExport = chartData.labels.map((label, index) => ({
      Month: label,
      Completed: chartData.datasets[0].data[index],
      Cancelled: chartData.datasets[1].data[index],
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bookings");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "BookingsData.xlsx");
  };

  useEffect(() => {
    if (CompletedTatData.length > 0) {
      let totalD0 = 0,
        totalD1 = 0,
        totalD2 = 0,
        totalD3 = 0,
        totalD4 = 0,
        totalD5 = 0,
        totalD6 = 0,
        totalD6plus = 0,
        totalD = 0;
      // Calculate totals
      CompletedTatData.forEach((element) => {
        totalD0 += Number(element.D0) || 0;
        totalD1 += Number(element.D1) || 0;
        totalD2 += Number(element.D2) || 0;
        totalD3 += Number(element.D3) || 0;
        totalD4 += Number(element.D4) || 0;
        totalD5 += Number(element.D5) || 0;
        totalD6 += Number(element.D6) || 0;
        totalD6plus += Number(element.D6plus) || 0;
        totalD += Number(element.total_bookings) || 0;
      });

      setChartTotal({
        totalD0,
        totalD1,
        totalD2,
        totalD3,
        totalD4,
        totalD5,
        totalD6,
        totalD6plus,
        totalD,
      });
    }
  }, [CompletedTatData]);

  useEffect(() => {
    if (openTatData.length > 0) {
      let totalD0 = 0,
        totalD1 = 0,
        totalD2 = 0,
        totalD3 = 0,
        totalD4 = 0,
        totalD5 = 0,
        totalD6 = 0,
        totalD6plus = 0,
        totalD = 0;
      // Calculate totals
      openTatData.forEach((element) => {
        totalD0 += Number(element.D0) || 0;
        totalD1 += Number(element.D1) || 0;
        totalD2 += Number(element.D2) || 0;
        totalD3 += Number(element.D3) || 0;
        totalD4 += Number(element.D4) || 0;
        totalD5 += Number(element.D5) || 0;
        totalD6 += Number(element.D6) || 0;
        totalD6plus += Number(element.D6plus) || 0;
        totalD += Number(element.total_bookings) || 0;
      });

      setOpenChartTotal({
        totalD0,
        totalD1,
        totalD2,
        totalD3,
        totalD4,
        totalD5,
        totalD6,
        totalD6plus,
        totalD,
      });
    }
  }, [openTatData]);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Dashboard"
        breadcrumbSubTitle=""
        navButton=""
        title=""
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <h5 className="mb-2">
                  Summary{" "}
                  <small>
                    <i>Current Month</i>
                  </small>
                </h5>

                <div className="row">
                  <div className="col-md-3 col-sm-6 col-12">
                    <div className="info-box">
                      <span className="info-box-icon bg-info">
                        <i className="far fa-registered" />
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">
                          New Call Registered
                        </span>
                        <span className="info-box-number">
                          {dashboardNumber.newRegisteredCount > 0 ? (
                            <Link to="/bookingsummary/1">
                              {dashboardNumber.newRegisteredCount}
                            </Link>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <div className="info-box">
                      <span className="info-box-icon bg-warning">
                        <i className="fas fa-book" />
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">Pending Bookings</span>
                        <span className="info-box-number">
                          {dashboardNumber.pendingCount > 0 ? (
                            <Link to="/bookingsummary/2">
                              {dashboardNumber.pendingCount}
                            </Link>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <div className="info-box">
                      <span className="info-box-icon bg-success">
                        <i className="far fa-thumbs-up" />
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">
                          Completed Bookings
                        </span>
                        <span className="info-box-number">
                          {dashboardNumber.completedCount > 0 ? (
                            <Link to="/bookingsummary/3">
                              {dashboardNumber.completedCount}
                            </Link>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-12">
                    <div className="info-box">
                      <span className="info-box-icon bg-danger">
                        <i className="fas fa-times" />
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">
                          Cancelled Bookings
                        </span>
                        <span className="info-box-number">
                          {dashboardNumber.cancelledCount > 0 ? (
                            <Link to="/bookingsummary/4">
                              {dashboardNumber.cancelledCount}
                            </Link>
                          ) : (
                            0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <section className="col-lg-12 connectedSortable ui-sortable">
                    <div className="card">
                      <div
                        className="card-header ui-sortable-handle"
                        style={{ cursor: "move" }}
                      >
                        <h3 className="card-title">
                          <i className="fas fa-chart-column mr-1" />
                          Open TAT Report
                        </h3>
                        <div
                          className="card-tools"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <ul
                            className="nav nav-tabs"
                            role="tablist"
                            style={{ margin: 0, padding: 0, display: "flex" }}
                          >
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                data-toggle="pill"
                                href="#"
                                role={"tab"}
                                onClick={() => {
                                  setOpenFormData({
                                    ...OpenformData,
                                    tabwise: 1
                                  });

                                  setOpenReloadList(false)
                                  setOpenReloadList(true)
                                }}
                              >
                                Zone
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="pill"
                                href="#"
                                role={"tab"}
                                onClick={() => {
                                  setOpenFormData({
                                    ...OpenformData,
                                    tabwise: 2
                                  });

                                  setOpenReloadList(false)
                                  setOpenReloadList(true)
                                }}
                              >
                                State
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="pill"
                                href="#"
                                role={"tab"}
                                onClick={() => {
                                  setOpenFormData({
                                    ...OpenformData,
                                    tabwise: 3
                                  });

                                  setOpenReloadList(false)
                                  setOpenReloadList(true)
                                }}
                              >
                                ASC
                              </Link>
                            </li>
                          </ul>
                          {opentatFilter ? (
                            <i
                              style={{ cursor: "pointer", marginLeft: "20px" }}
                              className="fa-solid fa-minus"
                              onClick={() => {
                                setOpentatFilter(!opentatFilter);
                              }}
                            ></i>
                          ) : (
                            <i
                              style={{ cursor: "pointer", marginLeft: "20px" }}
                              className="fa-solid fa-filter"
                              onClick={() => {
                                setOpentatFilter(!opentatFilter);
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="tab-content p-0">
                          {opentatFilter && (
                            <div
                              className="card-header ui-sortable-handle"
                              style={{ cursor: "move" }}
                            >
                              <div className="row">
                                {Number(loggedInUser.entity_type) !==
                                  PARTNER_TYPE && (
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>
                                        Partner{" "}
                                        <span style={{ color: "red" }}>
                                          {formErrors.partner_id}
                                        </span>
                                      </label>
                                      <Select
                                        name="partner_id"
                                        value={
                                          partnerlist.find(
                                            (option) =>
                                              option.value ===
                                              OpenformData.partner_id
                                          ) || null
                                        }
                                        options={partnerlist}
                                        onChange={(val) => {
                                          setOpenFormData({
                                            ...OpenformData,
                                            partner_id: val ? val.value : null, // Handle case when value is cleared
                                          });
                                        }}
                                        isClearable
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Appliance{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.appliance_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="appliance_id"
                                      value={
                                        appliancelist.find(
                                          (option) =>
                                            option.value ===
                                            OpenformData.appliance_id
                                        ) || null
                                      }
                                      options={appliancelist}
                                      onChange={(val) => {
                                        setOpenFormData({
                                          ...OpenformData,
                                          appliance_id: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Call Type{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.appliance_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="calltype"
                                      value={
                                        calltypelist.find(
                                          (option) =>
                                            option.value ===
                                            OpenformData.calltype_groupid
                                        ) || null
                                      }
                                      options={calltypelist}
                                      onChange={(val) => {
                                        setOpenFormData({
                                          ...OpenformData,
                                          calltype_groupid: val
                                            ? val.value
                                            : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Spare{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.is_spare}
                                      </span>
                                    </label>
                                    <Select
                                      name="is_spare"
                                      value={
                                        isSpareList.find(
                                          (option) =>
                                            option.value ===
                                            OpenformData.is_spare
                                        ) || null
                                      }
                                      options={isSpareList}
                                      onChange={(val) => {
                                        setOpenFormData({
                                          ...OpenformData,
                                          is_spare: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Upcountry{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.is_upcountry}
                                      </span>
                                    </label>
                                    <Select
                                      name="is_upcountry"
                                      value={
                                        isUpcountryList.find(
                                          (option) =>
                                            option.value ===
                                            OpenformData.is_upcountry
                                        ) || null
                                      }
                                      options={isUpcountryList}
                                      onChange={(val) => {
                                        setOpenFormData({
                                          ...OpenformData,
                                          is_upcountry: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      State{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.state_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="state_id"
                                      value={
                                        stateList.find(
                                          (option) =>
                                            option.value ===
                                            OpenformData.state_id
                                        ) || null
                                      }
                                      options={stateList}
                                      onChange={(val) => {
                                        setOpenFormData({
                                          ...OpenformData,
                                          state_id: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable // This allows users to clear the selection
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      From First Booking Date{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.from_date}
                                      </span>
                                    </label>
                                    <DatePicker
                                      className="form-control"
                                      selected={startOpenDate}
                                      onChange={handleOpenStartDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      isClearable
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={15}
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      To First Booking Date{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.to_date}
                                      </span>
                                    </label>
                                    <DatePicker
                                      className="form-control"
                                      selected={openEndDate}
                                      onChange={handleOpenEndDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      isClearable
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={15}
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      .{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.submit}
                                      </span>
                                    </label>
                                    <input
                                      name="submit"
                                      id="submit"
                                      type="submit"
                                      className="form-control btn btn-primary"
                                      onClick={() => {
                                        setOpenReloadList(true);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <br />
                              <br />
                            </div>
                          )}

                          <div className="row">
                            <table className="table table-bordered table-hover table-striped data dataTable no-footer">
                              <thead>
                                <tr>
                                  <th>SNo.</th>
                                  <th>{OpenformData.tabwise === 1 ? 'Zone' : OpenformData.tabwise === 2 ? 'State' : 'ASC Name'}</th>
                                  <th>D0</th>
                                  <th>D1</th>
                                  <th>D2</th>
                                  <th>D3</th>
                                  <th>D4</th>
                                  <th>D5</th>
                                  <th>D6</th>
                                  <th>D6+</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {openTatData.length > 0 ? (
                                  <>
                                    {openTatData.map((element, index) => (
                                      <tr className="text-center" key={index}>
                                        <td>{index + 1}</td>
                                        <td>{element.zone}</td>
                                        <td>
                                          {element.D0} {" ("}
                                          {(
                                            (Number(element.D0) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D1} {" ("}
                                          {(
                                            (Number(element.D1) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D2} {" ("}
                                          {(
                                            (Number(element.D2) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D3} {" ("}
                                          {(
                                            (Number(element.D3) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D4} {" ("}
                                          {(
                                            (Number(element.D4) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D5} {" ("}
                                          {(
                                            (Number(element.D5) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D6} {" ("}
                                          {(
                                            (Number(element.D6) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D6plus} {" ("}
                                          {(
                                            (Number(element.D6plus) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>{element.total_bookings}</td>
                                      </tr>
                                    ))}
                                    <tr className="text-center">
                                      <td>{CompletedTatData.length + 1}</td>
                                      <td>Total</td>
                                      <td>
                                        {openChartTotal.totalD0} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD0) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {openChartTotal.totalD1} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD1) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {openChartTotal.totalD2} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD2) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {openChartTotal.totalD3} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD3) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {openChartTotal.totalD4} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD4) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {openChartTotal.totalD5} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD5) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {openChartTotal.totalD6} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD6) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {openChartTotal.totalD6plus} {" ("}
                                        {(
                                          (Number(openChartTotal.totalD6plus) /
                                            Number(openChartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>{openChartTotal.totalD}</td>
                                    </tr>
                                  </>
                                ) : (
                                  <p style={{ padding: "20px" }}>
                                    Data Not Found
                                  </p>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="row">
                  <section className="col-lg-12 connectedSortable ui-sortable">
                    <div className="card">
                      <div
                        className="card-header ui-sortable-handle"
                        style={{ cursor: "move" }}
                      >
                        <h3 className="card-title">
                          <i className="fas fa-chart-column mr-1" />
                          Closed TAT Report
                        </h3>

                        <div
                          className="card-tools"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <ul
                            className="nav nav-tabs"
                            role="tablist"
                            style={{ margin: 0, padding: 0, display: "flex" }}
                          >
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                data-toggle="pill"
                                href="#"
                                role={"tab"}
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    tabwise: 1
                                  });

                                  setReload(false)
                                  setReload(true)
                                }}
                              >
                                Zone
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="pill"
                                href="#"
                                role={"tab"}
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    tabwise: 2
                                  });

                                  setReload(false)
                                  setReload(true)
                                }}
                              >
                                State
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="pill"
                                href="#"
                                role={"tab"}
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    tabwise: 3
                                  });

                                  setReload(false)
                                  setReload(true)
                                }}
                              >
                                ASC
                              </Link>
                            </li>
                          </ul>
                          {closedtatFilter ? (
                            <i
                              style={{ cursor: "pointer", marginLeft: "20px" }}
                              className="fa-solid fa-minus"
                              onClick={() => {
                                setClosedtatFilter(!closedtatFilter);
                              }}
                            ></i>
                          ) : (
                            <i
                              style={{ cursor: "pointer", marginLeft: "20px" }}
                              className="fa-solid fa-filter"
                              onClick={() => {
                                setClosedtatFilter(!closedtatFilter);
                              }}
                            ></i>
                          )}

                          
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="tab-content p-0">
                          {closedtatFilter && (
                            <div
                              className="card-header ui-sortable-handle"
                              style={{ cursor: "move" }}
                            >
                              <div className="row">
                                {Number(loggedInUser.entity_type) !==
                                  PARTNER_TYPE && (
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>
                                        Partner{" "}
                                        <span style={{ color: "red" }}>
                                          {formErrors.partner_id}
                                        </span>
                                      </label>
                                      <Select
                                        name="partner_id"
                                        value={
                                          partnerlist.find(
                                            (option) =>
                                              option.value ===
                                              formData.partner_id
                                          ) || null
                                        }
                                        options={partnerlist}
                                        onChange={(val) => {
                                          setFormData({
                                            ...formData,
                                            partner_id: val ? val.value : null, // Handle case when value is cleared
                                          });
                                        }}
                                        isClearable
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Appliance{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.appliance_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="appliance_id"
                                      value={
                                        appliancelist.find(
                                          (option) =>
                                            option.value ===
                                            formData.appliance_id
                                        ) || null
                                      }
                                      options={appliancelist}
                                      onChange={(val) => {
                                        setFormData({
                                          ...formData,
                                          appliance_id: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Call Type{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.appliance_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="calltype"
                                      value={
                                        calltypelist.find(
                                          (option) =>
                                            option.value ===
                                            formData.calltype_groupid
                                        ) || null
                                      }
                                      options={calltypelist}
                                      onChange={(val) => {
                                        setFormData({
                                          ...formData,
                                          calltype_groupid: val
                                            ? val.value
                                            : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Spare{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.is_spare}
                                      </span>
                                    </label>
                                    <Select
                                      name="is_spare"
                                      value={
                                        isSpareList.find(
                                          (option) =>
                                            option.value === formData.is_spare
                                        ) || null
                                      }
                                      options={isSpareList}
                                      onChange={(val) => {
                                        setFormData({
                                          ...formData,
                                          is_spare: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Upcountry{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.is_upcountry}
                                      </span>
                                    </label>
                                    <Select
                                      name="is_upcountry"
                                      value={
                                        isUpcountryList.find(
                                          (option) =>
                                            option.value ===
                                            formData.is_upcountry
                                        ) || null
                                      }
                                      options={isUpcountryList}
                                      onChange={(val) => {
                                        setFormData({
                                          ...formData,
                                          is_upcountry: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Status{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.current_status}
                                      </span>
                                    </label>
                                    <Select
                                      name="current_status"
                                      value={
                                        closedStatusList.find(
                                          (option) =>
                                            option.value ===
                                            formData.current_status
                                        ) || null
                                      }
                                      options={closedStatusList}
                                      onChange={(val) => {
                                        setFormData({
                                          ...formData,
                                          current_status: val.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      State{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.state_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="state_id"
                                      value={
                                        stateList.find(
                                          (option) =>
                                            option.value === formData.state_id
                                        ) || null
                                      }
                                      options={stateList}
                                      onChange={(val) => {
                                        setFormData({
                                          ...formData,
                                          state_id: val ? val.value : null, // Handle case when value is cleared
                                        });
                                      }}
                                      isClearable // This allows users to clear the selection
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      From Closed Date{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.from_date}
                                      </span>
                                    </label>
                                    <DatePicker
                                      className="form-control"
                                      selected={startDate}
                                      onChange={handleStartDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      isClearable
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={15}
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      To Closed Date{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.to_date}
                                      </span>
                                    </label>
                                    <DatePicker
                                      className="form-control"
                                      selected={endDate}
                                      onChange={handleEndDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      isClearable
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={15}
                                      showMonthDropdown
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      .{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.submit}
                                      </span>
                                    </label>
                                    <input
                                      name="submit"
                                      id="submit"
                                      type="submit"
                                      className="form-control btn btn-primary"
                                      onClick={() => {
                                        setReload(true);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <br />
                              <br />
                            </div>
                          )}

                          <div className="row">
                            <table className="table table-bordered  table-hover table-striped data dataTable no-footer">
                              <thead>
                                <tr>
                                  <th>SNo.</th>
                                  <th>{formData.tabwise === 1 ? 'Zone' : formData.tabwise === 2 ? 'State' : 'ASC Name'}</th>
                                  <th>D0</th>
                                  <th>D1</th>
                                  <th>D2</th>
                                  <th>D3</th>
                                  <th>D4</th>
                                  <th>D5</th>
                                  <th>D6</th>
                                  <th>D6+</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {CompletedTatData.length > 0 ? (
                                  <>
                                    {CompletedTatData.map((element, index) => (
                                      <tr className="text-center" key={index}>
                                        <td>{index + 1}</td>
                                        <td>{element.zone}</td>
                                        <td>
                                          {element.D0} {" ("}
                                          {(
                                            (Number(element.D0) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D1} {" ("}
                                          {(
                                            (Number(element.D1) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D2} {" ("}
                                          {(
                                            (Number(element.D2) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D3} {" ("}
                                          {(
                                            (Number(element.D3) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D4} {" ("}
                                          {(
                                            (Number(element.D4) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D5} {" ("}
                                          {(
                                            (Number(element.D5) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D6} {" ("}
                                          {(
                                            (Number(element.D6) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>
                                          {element.D6plus} {" ("}
                                          {(
                                            (Number(element.D6plus) /
                                              Number(element.total_bookings)) *
                                            100
                                          ).toFixed(2)}
                                          {"%)"}
                                        </td>
                                        <td>{element.total_bookings}</td>
                                      </tr>
                                    ))}
                                    <tr className="text-center">
                                      <td>{CompletedTatData.length + 1}</td>
                                      <td>Total</td>
                                      <td>
                                        {chartTotal.totalD0} {" ("}
                                        {(
                                          (Number(chartTotal.totalD0) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {chartTotal.totalD1} {" ("}
                                        {(
                                          (Number(chartTotal.totalD1) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {chartTotal.totalD2} {" ("}
                                        {(
                                          (Number(chartTotal.totalD2) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {chartTotal.totalD3} {" ("}
                                        {(
                                          (Number(chartTotal.totalD3) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {chartTotal.totalD4} {" ("}
                                        {(
                                          (Number(chartTotal.totalD4) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {chartTotal.totalD5} {" ("}
                                        {(
                                          (Number(chartTotal.totalD5) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {chartTotal.totalD6} {" ("}
                                        {(
                                          (Number(chartTotal.totalD6) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>
                                        {chartTotal.totalD6plus} {" ("}
                                        {(
                                          (Number(chartTotal.totalD6plus) /
                                            Number(chartTotal.totalD)) *
                                          100
                                        ).toFixed(2)}
                                        {"%)"}
                                      </td>
                                      <td>{chartTotal.totalD}</td>
                                    </tr>
                                  </>
                                ) : (
                                  <p style={{ padding: "20px" }}>
                                    Data Not Found
                                  </p>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="row">
                  <section className="col-lg-6 connectedSortable ui-sortable">
                    <div className="card">
                      <div
                        className="card-header ui-sortable-handle"
                        style={{ cursor: "move" }}
                      >
                        <h3 className="card-title">
                          <i className="fas fa-chart-column mr-1" />
                          Booking Closed
                        </h3>
                        <div className="card-tools">
                          <button
                            className="btn btn-primary"
                            onClick={exportToExcel}
                          >
                            <i className="fa-solid fa-download"></i>
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="tab-content p-0">
                          <Bar
                            data={chartData}
                            options={{
                              scales: {
                                x: {
                                  grid: { display: false },
                                },
                                y: {
                                  grid: { display: false },
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default Dashboard;
