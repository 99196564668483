import {
  LABEL_APPLIANCE_DETAILS,
  LABEL_SERVICE_CATEGORY,
  LABEL_CALL_CHARGES,
} from "../../../constant/label_constant";
import { EMPLOYEE_TYPE, SF_TYPE } from "../../../constant/status_constant";
import { openSignUrl } from "../../../utils/HelperFunction";

const ApplianceDetailsTab = ({
  userApplianceDetails,
  bookingCharges,
  bookingUpcountry,
  spareParts,
  loggedInUser,
}) => {
  return (
    <>
      <div className="active tab-pane" id="booking_details">
        <section className="content">
          {/* Default box */}

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 d-flex align-items-stretch flex-column">
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0">
                  {LABEL_APPLIANCE_DETAILS}
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>Appliance</th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Capacity</th>
                            <th>Model Number</th>
                            <th>
                              Purchase Date /<br />
                              SC Purchase Date
                            </th>
                            <th>
                              Serial Number /<br />
                              SC Serial Number
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            <td>{userApplianceDetails.appliance_name}</td>
                            <td>{userApplianceDetails.brand_name}</td>
                            <td>{userApplianceDetails.category_name}</td>
                            <td>{userApplianceDetails.capacity_name}</td>
                            <td>{userApplianceDetails.model_number}</td>
                            <td>
                              {userApplianceDetails.purchase_date} /<br />
                              {userApplianceDetails.sf_purchase_date}
                            </td>
                            <td>
                              {userApplianceDetails.serial_number} /<br />
                              {userApplianceDetails.sf_serial_number}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    {userApplianceDetails.invoice_file && (
                      <>
                        <button
                          style={{ marginRight: "5px" }}
                          onClick={() => {
                            openSignUrl(userApplianceDetails.invoice_file);
                          }}
                          className="btn btn-sm btn-primary"
                        >
                          <i className="fas fa-download" /> Invoice File
                        </button>
                      </>
                    )}

                    {userApplianceDetails.warranty_file && (
                      <>
                        <button
                          onClick={() => {
                            openSignUrl(userApplianceDetails.warranty_file);
                          }}
                          className="btn btn-sm btn-primary"
                        >
                          <i className="fas fa-download" /> Warranty File
                        </button>
                      </>
                    )}

                    {userApplianceDetails.serial_number_file && (
                      <>
                        <button
                          onClick={() => {
                            openSignUrl(
                              userApplianceDetails.serial_number_file
                            );
                          }}
                          className="btn btn-sm btn-primary"
                        >
                          <i className="fas fa-download" /> Serial Number File
                        </button>
                      </>
                    )}
                    {userApplianceDetails.sf_serial_number_file && (
                      <>
                        <button
                          onClick={() => {
                            openSignUrl(
                              userApplianceDetails.sf_serial_number_file
                            );
                          }}
                          className="btn btn-sm btn-primary"
                        >
                          <i className="fas fa-download" /> SC Serial Number
                          File
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 d-flex align-items-stretch flex-column">
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0">
                  {LABEL_SERVICE_CATEGORY}
                </div>

                {bookingCharges.map((item, index) => (
                  <div key={index}>
                    <div className="card-body pt-0">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <table className="table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>{LABEL_SERVICE_CATEGORY}</th>
                                  {Number(loggedInUser.entity_type) !== SF_TYPE && (
                                    <th>{LABEL_CALL_CHARGES}</th>
                                  )}
                                  
                                  <th>Customer Payable</th>
                                
                                  {(Number(loggedInUser.entity_type) === EMPLOYEE_TYPE ||
                                    Number(loggedInUser.entity_type) === SF_TYPE) && (
                                      <>
                                        <th>Sc Earning</th>
                                        <th>Sc Paid</th>
                                      </>
                                      
                                    )}

                                  <th>Amount Collected</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="text-center">
                                  <td>{item.call_type["call_type"]}</td>
                                  
                                  {Number(loggedInUser.entity_type) !== SF_TYPE && (
                                    <td>Rs. {item.call_charges}</td>
                                  )}
                                  <td>Rs. {item.customer_payable}</td>
                                  {(Number(loggedInUser.entity_type) === EMPLOYEE_TYPE ||
                                    Number(loggedInUser.entity_type) === SF_TYPE) && (
                                      <>
                                      <td>Rs. {Number(item.customer_payable) === 0 ? (Number(item.call_charges) + Number(item.amount_paid)) * Number(item.sf_percentage)/100 : Number(item.amount_paid) > 0 ? Number(item.amount_paid) * Number(item.sf_percentage)/100 : Number(item.sf_amount_paid)}</td>
                                      <td>Rs. {item.sf_amount_paid}</td>
                                      </>
                                    )}
                                  <td>Rs. {item.amount_paid}</td>
                                  <td>{item.status}</td>
                                </tr>

                                {bookingUpcountry.length > 0 &&
                                  bookingUpcountry[0].is_upcountry === 1 && (
                                    <tr className="text-center">
                                      <td>Upcountry</td>
                                      {Number(loggedInUser.entity_type) !== SF_TYPE && (
                                        <td>
                                          Rs.{" "}
                                          {bookingUpcountry[0].upcountry_partner_rate *
                                            bookingUpcountry[0]
                                              .upcountry_distance}
                                        </td>
                                      )}
                                      <td>
                                        Rs.{" "}
                                        {bookingUpcountry[0]
                                          .upcountry_paid_by === 2
                                          ? bookingUpcountry[0]
                                              .upcountry_partner_rate *
                                            bookingUpcountry[0]
                                              .upcountry_distance
                                          : 0}
                                      </td>
                                      {(Number(loggedInUser.entity_type) ==
                                        EMPLOYEE_TYPE ||
                                        Number(loggedInUser.entity_type) == SF_TYPE) && (
                                          <>
                                          <td>
                                            Rs.{" "}
                                            {bookingUpcountry[0]
                                                .sf_upcountry_paid_amount > 0

                                            ? (bookingUpcountry[0]
                                            .sf_upcountry_paid_amount) * .70

                                            : bookingUpcountry[0]
                                            .upcountry_sf_rate *
                                          bookingUpcountry[0]
                                            .upcountry_distance}
                                          </td>
                                          <td>
                                            Rs.{" "}
                                            {
                                              bookingUpcountry[0]
                                                .sf_upcountry_paid_amount
                                            }
                                          </td>
                                          </>
                                          
                                        )}
                                      <td>
                                        Rs.{" "}
                                        {
                                          bookingUpcountry[0]
                                            .upcountry_paid_amount
                                        }
                                      </td>
                                      <td>
                                        {bookingUpcountry[0].current_status}
                                      </td>
                                    </tr>
                                  )}

                                {spareParts.map(
                                  (part, partIndex) =>
                                    part.warranty_status === 2 && (
                                      <tr
                                        className="text-center"
                                        key={partIndex}
                                      >
                                        <td>Spare Parts</td>
                                        {Number(loggedInUser.entity_type) !== SF_TYPE && (
                                          <td>Rs. {part.estimate_price}</td>
                                        )}
                                        <td>Rs. {part.estimate_price}</td>
                                        {(Number(loggedInUser.entity_type) ==
                                          EMPLOYEE_TYPE ||
                                          Number(loggedInUser.entity_type) ==
                                            SF_TYPE) && (
                                            <>
                                              <td>Rs. {part.sc_price}</td>
                                              <td>Rs. {part.customer_paid}</td>
                                            </>
                                          )}
                                        <td>Rs. {part.customer_paid}</td>
                                        <td>{part.status}</td>
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* /.card-body */}

          {/* /.card-footer */}
          {/* /.card */}
        </section>
      </div>
    </>
  );
};

export default ApplianceDetailsTab;
