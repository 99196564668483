import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MainBody from '../../../component/MainBody';
import { LABEL_JOB } from '../../../constant/label_constant';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import httpRequest from '../../../utils/Http';
import { toast } from 'react-toastify';

const ReopenClosedBooking = () => {
    const { eid } = useParams();
    const navigate = useNavigate();
    const [isSubmit, setSubmit] = useState(false);
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [appointmentDate, setAppointmentDate] = useState();

    useEffect(() => {
        (async () => {
          if (eid) {
            const bid = atob(eid);
            const data = await httpRequest("getcalldetails?id=" + bid, {}, "GET");
    
            if (data.status === true) {
              if (
                data.bookingData.sc_closed_date &&
                data.bookingData.closed_date
              ) {
                setFormData({
                  ...formData,
                  booking_id: data.bookingData.booking_id,
                  id: data.bookingData.id
                });
              } else {
                toast.error(
                  `${LABEL_JOB} is not closed. You cannot reopen this booking`
                );
                navigate("/pendingbooking");
              }
            } else {
              toast.error(`${LABEL_JOB} Not Found`);
              navigate("/404");
            }
          } else {
           toast.error(`${LABEL_JOB} Not Found`);
           navigate("/404");
          }
        })();
      }, [eid]);

      const onSubmitHandler = (e) => {
        e.preventDefault();
        setSubmit(false);
        setFormErrors(validateFrom(formData));
        setSubmit(true);
      };
    
      const validateFrom = (value) => {
        const errors = {};
    
        if (!value.remarks) {
          errors.remarks = "Remarks is Required";
        }
        if (!value.appointment_date) {
          errors.appointment_date = "Appointment is Required";
        }
    
        return errors;
      };
    
      useEffect(() => {
        (async () => {
    
          if (Object.keys(formErrors).length === 0 && isSubmit) {
            
    
            const data = await httpRequest(
              "processreopenbooking",
              formData,
              "POST"
            );
    
            setSubmit(false);
            if (data.status === true && data.message) {
              document.getElementById("closedBooking").reset();
              toast.success(data.message);
              navigate("/getcompletedlist");
            } else {
              toast.error(data.message);
            }
          }
        })();
        //eslint-disable-next-line
      }, [isSubmit, formErrors]);
  return (
    <div>
    <MainBody
      breadcrumbTitle="Reopen Closed Booking"
      breadcrumbSubTitle={formData.booking_id}
      navButton={""}
      title={""}
      component={
        <>
          <section className="content">
            <div className="container-fluid">
              <form className="form-horizontal" id="closedBooking" onSubmit={onSubmitHandler}>
                <div className="card card-default">
                  <div className="card-header">
                    <h3 className="card-title">Reopen Closed Booking</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label
                            htmlFor="bookingid"
                            className="col-md-2 col-form-label"
                          >
                            {LABEL_JOB} Id *{" "}
                            <span style={{ color: "red" }}>
                              {formErrors.bookingid}
                            </span>
                          </label>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control"
                              id="bookingid"
                              placeholder="Enter Booking Id"
                              name="bookingid"
                              value={formData.booking_id}
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            htmlFor="appointment_date"
                            className="col-md-2 col-form-label"
                          >
                            Appointment Date *{" "}
                            <span style={{ color: "red" }}>
                              {formErrors.appointment_date}
                            </span>
                          </label>
                          <div className="col-md-4">
                          <DatePicker
                            className="form-control text-capitalize"
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            selected={appointmentDate}
                            placeholderText="Select Appointment Date"
                            onChange={(date) => {
                              setFormData({
                                ...formData,
                                appointment_date: date,
                              });
                              setAppointmentDate(date);
                            }}
                          />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            htmlFor="remarks"
                            className="col-md-2 col-form-label"
                          >
                            Remarks *{" "}
                            <span style={{ color: "red" }}>
                              {formErrors.remarks}
                            </span>
                          </label>
                          <div className="col-md-4">
                            <textarea
                              type="text"
                              className="form-control"
                              id="remarks"
                              placeholder="Enter Remarks"
                              name="remarks"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="offset-sm-3 col-sm-10">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </>
      }
    />
  </div>
  )
}

export default ReopenClosedBooking