import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  LABEL_JOB_NUMBER,
  LABEL_APPLIACE_NAME,
  LABEL_JOB_DATE,
} from "../../../constant/label_constant";
import {
  RESCHEDULE_ACTION_PERMISSION,
  COMPLETION_ACTION_PERMISSION,
  CANCELLATION_ACTION_PERMISSION,
  SF_ACTION_PERMISSION,
} from "../../../constant/action_permission";
import moment from "moment";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import httpRequest from "../../../utils/Http";
import {
  COMPLETED_STATUS,
  CANCELLED_STATUS,
  REPEAT_CALL_DAYS,
} from "../../../constant/status_constant";
import MainBody from "../../../component/MainBody";
import { getDateDiff } from "../../../utils/HelperFunction";

const AdvanceSearch = () => {
  const [searchOption, setSearchOption] = useState([
    { label: "Booking ID", value: "booking_id" },
    { label: "Order ID/ Invoice ID", value: "order_id" },
    { label: "Mobile Number", value: "mobile_number" },
    { label: "Serial Number", value: "serial_number" },
  ]);

  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isshowOption, setShowOption] = useState(null);
  const [searchFrom, setsearchFrom] = useState(null);
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);

  const validateFrom = (value) => {
    const error = {};
    if (!value.searchFrom) {
      error.searchFrom = "Search From Required";
    }

    if (!value.searchText) {
      error.searchText = "Please type Keyword";
    }

    return error;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    console.log(formData);
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const keyword = urlParams.get("keyword");
    if (keyword !== null) {
      setShowOption(keyword);
      //let a = {  };
      setSearchOption([
        ...searchOption,
        { label: "Booking ID /Mobile Number", value: "mbid" },
      ]);
      setsearchFrom({ label: "Booking ID /Mobile Number", value: "mbid" });
      setFormData({ searchText: keyword, searchFrom: "mbid" });
      setSubmit(false);
      setFormErrors(validateFrom({ searchText: keyword, searchFrom: "mbid" }));
      setSubmit(true);
    } else {
      setShowOption(null);
      setFormData({});
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const listdata = await httpRequest(
          "search?searchfrom=" +
            formData.searchFrom +
            "&searchtext=" +
            formData.searchText,
          {},
          "GET"
        );

        if (listdata.status === true && listdata.message.length > 0) {
          setDataList(listdata.message);
        } else {
          setDataList([]);
          toast.error(listdata.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <>
      <MainBody
        breadcrumbTitle="Search"
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <>
            <div className="">
              {/* Content Header (Page header) */}
              <section className="content-header">
                <div className="container-fluid">
                  <h2 className="text-center display-4">
                    {isshowOption == null && <>Advance</>} Search
                  </h2>
                </div>
                {/* /.container-fluid */}
              </section>
              {/* Main content */}
              <section className="content">
                <div className="container-fluid">
                  <form id="searchForm" onSubmit={onSubmitHandler}>
                    <div className="row">
                      <div className="col-md-10 offset-md-1">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label>
                                Search From:{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.searchFrom}
                                </span>
                              </label>
                              <div
                                style={{
                                  overflow: "visible !important",
                                  position: "relative",
                                  zIndex: 100,
                                  display: "block",
                                }}
                              >
                                <Select
                                  name="search"
                                  placeholder="Search From"
                                  options={searchOption}
                                  value={searchFrom}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      searchFrom: val.value,
                                    });
                                    setsearchFrom(val);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="input-group input-group-lg">
                            <input
                              type="search"
                              className="form-control form-control-lg text-capitalize"
                              placeholder="Type your keywords here"
                              defaultValue={isshowOption}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  searchText: e.target.value,
                                });
                              }}
                            />

                            <div className="input-group-append">
                              <button
                                type="submit"
                                className="btn btn-lg btn-default"
                              >
                                <i className="fa fa-search" />
                              </button>
                            </div>
                          </div>
                          <span style={{ color: "red" }}>
                            {formErrors.searchText}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>

                  {dataList.map((item, index) => (
                    <div
                      className="card card-solid col-md-10 offset-md-1"
                      key={"cardsolid" + index}
                      style={{
                        backgroundColor: "inherit",
                      }}
                    >
                      <div className="card-body pb-0" key={"cardbody" + index}>
                        <div className="row" key={"cardrow" + index}>
                          <div
                            className="col-12 col-sm-6 col-md-12 d-flex align-items-stretch flex-column"
                            key={"alig-item" + index}
                          >
                            <div
                              className="card bg-light d-flex flex-fill"
                              key={"cardlight" + index}
                            >
                              <div
                                className="card-header text-muted border-bottom-0"
                                key={"cardheader" + index}
                              >
                                <b>{LABEL_JOB_NUMBER}:</b>
                                <NavLink
                                  to={`/getbookingdetails?bid=${btoa(item.id)}`}
                                >
                                  {item.booking_id}
                                </NavLink>
                                <div
                                  className="col-md-3"
                                  style={{ float: "right" }}
                                >
                                  <span key={"spankey" + item}>
                                    <b>{LABEL_JOB_DATE}: </b>{" "}
                                    {moment(item.booking_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ float: "right" }}
                                >
                                  <span
                                    key={"spankey" + item}
                                    style={{ float: "right" }}
                                  >
                                    <b>Registration Date: </b>{" "}
                                    {moment(item.createdAt).format(
                                      "DD MMM YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="card-body pt-0"
                                key={"cardbodyp" + index}
                              >
                                <div className="row" key={"rowcardp" + index}>
                                  <div
                                    className="col-12 col-sm-6 col-md-4"
                                    key={"cardsolidcol" + index}
                                  >
                                    <p>
                                      <b>Customer Name:</b>{" "}
                                      {item.user_detail.customer_name}
                                    </p>
                                    <p>
                                      <b>Phone:</b>{" "}
                                      {item.booking_primary_mobile_number}{" "}
                                      {item.booking_alternate_mobile_number && (
                                        <>
                                          /{" "}
                                          {item.booking_alternate_mobile_number}
                                        </>
                                      )}
                                    </p>
                                    <p>
                                      <b>Address:</b>{" "}
                                      {item.booking_address +
                                        ", " +
                                        item.booking_city +
                                        ", " +
                                        item.booking_state +
                                        ", Pincode - " +
                                        item.booking_pincode}
                                    </p>
                                  </div>
                                  <div
                                    className="col-12 col-sm-6 col-md-4"
                                    key={"cardsolidsm" + index}
                                  >
                                    <p>
                                      <b>Order ID/ Invoice ID:</b>{" "}
                                      {item.order_id}{" "}
                                      {item.sf_invoice_id != null && (
                                        <>/ {item.sf_invoice_id}</>
                                      )}
                                    </p>
                                    <p>
                                      <b>{LABEL_APPLIACE_NAME}:</b>{" "}
                                      {item.appliance.appliance_name} (
                                      {item.calltype_group.type})
                                    </p>
                                    <p>
                                      <b>Brand:</b>{" "}
                                      {
                                        item.user_appliance_detail.brand
                                          .brand_name
                                      }
                                    </p>
                                    <p>
                                      <b>Model Number:</b>{" "}
                                      {
                                        item.user_appliance_detail
                                          .appliance_model.model_number
                                      }
                                    </p>
                                  </div>

                                  <div className="col-12 col-sm-6 col-md-4">
                                    <p>
                                      <b>Serial Number:</b>{" "}
                                      {item.user_appliance_detail.serial_number}{" "}
                                      {item.user_appliance_detail
                                        .sf_serial_number != null && (
                                        <>
                                          /{" "}
                                          {
                                            item.user_appliance_detail
                                              .sf_serial_number
                                          }
                                        </>
                                      )}
                                    </p>
                                    <p>
                                      <b>Service Centre:</b>{" "}
                                      {item.service_centre != null && (
                                        <>{item.service_centre.display_name}</>
                                      )}
                                    </p>
                                    <p>
                                      <b>Status:</b>{" "}
                                      {item.partner_current_status}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer">
                                <div className="text-right">
                                  {item.current_status !== COMPLETED_STATUS &&
                                    item.current_status !==
                                      CANCELLED_STATUS && (
                                      <>
                                        <div
                                          className="input-group-prepend"
                                          style={{ float: "right" }}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                          >
                                            Action
                                          </button>
                                          <div className="dropdown-menu">
                                            {CANCELLATION_ACTION_PERMISSION.includes(
                                              loggedInUser.role_id
                                            ) && (
                                              <>
                                                <NavLink
                                                  className="dropdown-item nav-link"
                                                  to={`/cancel/${btoa(
                                                    item.id
                                                  )}`}
                                                >
                                                  Cancel
                                                </NavLink>
                                              </>
                                            )}

                                            {COMPLETION_ACTION_PERMISSION.includes(
                                              loggedInUser.role_id
                                            ) && (
                                              <>
                                                <div className="dropdown-divider" />
                                                <NavLink
                                                  className="dropdown-item nav-link"
                                                  to={`/updatebooking/${btoa(
                                                    item.id
                                                  )}/${btoa(2)}`}
                                                >
                                                  Complete
                                                </NavLink>
                                              </>
                                            )}
                                            {SF_ACTION_PERMISSION.includes(
                                              loggedInUser.role_id
                                            ) &&
                                              item.calltype_group
                                                .is_part_order === true && (
                                                <>
                                                  <div className="dropdown-divider" />

                                                  <NavLink
                                                    className="dropdown-item nav-link"
                                                    to={`/requestpartorder/${btoa(
                                                      item.id
                                                    )}`}
                                                  >
                                                    Part Order
                                                  </NavLink>
                                                </>
                                              )}

                                            {RESCHEDULE_ACTION_PERMISSION.includes(
                                              loggedInUser.role_id
                                            ) && (
                                              <>
                                                <div className="dropdown-divider" />
                                                <NavLink
                                                  className="dropdown-item nav-link"
                                                  to={`/reschedule/${btoa(
                                                    item.id
                                                  )}`}
                                                >
                                                  Reschedule
                                                </NavLink>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}

                                  {item.current_status === COMPLETED_STATUS && item.sc_closed_date && getDateDiff(item.sc_closed_date) < REPEAT_CALL_DAYS && (
                                    <>
                                      <div
                                        className="input-group-prepend"
                                        style={{ float: "right" }}
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-primary dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          Action
                                        </button>
                                        <div className="dropdown-menu">
                                          <>
                                            <NavLink
                                              className="dropdown-item nav-link"
                                              to={`/createbooking?parentbid=${btoa(
                                                item.id
                                              )}`}
                                            >
                                              Create Repeat Booking
                                            </NavLink>
                                          </>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </>
        }
      />
    </>
  );
};

export default AdvanceSearch;
