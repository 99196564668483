import React, { useEffect, useState } from "react";
import Switch from "../../../component/Switch";
import MyDataTable from "../../../component/MyDataTable";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { isValidEmail, isValidMobile } from "../../../utils/HelperFunction";
import { EMPLOYEE_TYPE, PARTNER_TYPE } from "../../../constant/status_constant";

const PartnerLogins = ({ partnerId, role_id, partnerDetails }) => {
  const [reloadList, setReload] = useState(true);
  const [roleList, setRole] = useState([]);
  // const [agentList, setAgent] = useState([]);
  const [formData, setFormData] = useState({ entity_id: partnerId });
  const [isSubmit, setSubmit] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [warehouse_style, setWarehoueStyle] = useState(
    "form-group hide_display"
  );
  const [warehouseList, setWarehouseList] = useState([]);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);

    if (isDivVisible === false) {
      setFormData({ entity_id: partnerId });
    }
  };

  useEffect(() => {
    (async () => {
      console.log(partnerDetails);
      const entity_type =
        Number(partnerDetails.is_default) === 1 ? EMPLOYEE_TYPE : PARTNER_TYPE;
      const roleData = await httpRequest(
        `getrolelist?active=1&entity_type=${entity_type}`,
        {},
        "GET"
      );
      setRole(roleData.message);

      const warehouselist = await httpRequest(
        `getWarehouseList?partner_id=${partnerId}`,
        {},
        "GET"
      );

      if (warehouselist.status == true) {
        //console.log(roleData.message);
        setWarehouseList(warehouselist.data);
      }
    })();
  }, [partnerDetails]);

  const updateToggleHandler = async (agent_id, value) => {
    try {
      setReload(false);
      let postData = {
        agent_id,
        active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updateagentstatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);

        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {}
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true
    },
    {
      name: "Email ID",
      selector: (row) => row.email,
      wrap: true
    },

    {
      name: "Mobile Number",
      selector: (row) => row.mobile_number,
      wrap: true
    },
    {
      name: "Role",
      selector: (row) => (row.role === null ? "" : row.role["role"]),
    },
   {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
      wrap: true
    },
    Number(role_id) === 1 && {
      name: "Status",
      selector: (row) =>
        Number(row.active) === 1 ? "Activated" : "DeActivated",
      cell: (row) => (
        <Switch
          isOn={Number(row.active) === 1 ? true : false}
          id={row.agent_id}
          handleToggle={(e) => {
            updateToggleHandler(row.agent_id, e.target.checked);
          }}
          onColor="#EF476F"
        />
      ),
      wrap: true
    },
    Number(role_id) === 1 && {
      name: "Edit",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            setFormData({
              entity_id: partnerId,
              agent_id: row.agent_id,
              name: row.name,
              email: row.email,
              mobile_number: row.mobile_number,
              role_id: row.role_id,
              warehouse_id: row.warehouse_id,
            });
            if (Number(row.role_id) === 5 || Number(row.role_id) === 6) {
              setWarehoueStyle("form-group");
            } else {
              setWarehoueStyle("form-group hide_display");
            }
            setIsDivVisible(true);
          }}
          ><i className="fas fa-pencil"></i></button>
      ),
      wrap: true
    },
  ].filter(Boolean);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    if (!value.name) {
      errors.name = "Name is Required";
    }

    if (!value.email) {
      errors.email = "Email ID is Required";
    } else if (!isValidEmail(value.email)) {
      errors.email = "Enter Valid Email ID";
    }

    if (!value.mobile_number) {
      errors.mobile_number = "Mobile Number is Required";
    } else {
      if (!isValidMobile(value.mobile_number)) {
        errors.mobile_number = "Mobile Number is not valid";
      }
    }
    if (!value.role_id) {
      errors.role_id = "Role is Required";
    } else if (value.role_id === 5 || value.role_id === 6) {
      if (!value.warehouse_id) {
        errors.warehouse_id = "Warehouse is Required";
      }
    }
    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest("createpartneragent", formData, "POST");
        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          document.getElementById("createlogin").reset();
          setFormData({ entity_id: partnerId });
          setReload(true);
          setIsDivVisible(false);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <>
      {isDivVisible && (
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add Agent</h3>
                  {role_id == 1 && (
                    <div className="card-tools">
                      <button
                        className="btn btn-sm btn-tools"
                        onClick={toggleDivVisibility}
                      >
                        {isDivVisible === false ? (
                          ""
                        ) : (
                          <i className="fas fa-minus"></i>
                        )}
                      </button>
                    </div>
                  )}
                </div>
                <form
                  className="form-horizontal"
                  id="createlogin"
                  onSubmit={onSubmitHandler}
                  action="javascritp:void(0)"
                >
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputName">
                        Name *{" "}
                        <span style={{ color: "red" }}>{formErrors.name}</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        defaultValue={formData.name}
                        placeholder="Enter Name"
                        name="name"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputEmail">
                        Email ID *{" "}
                        <span style={{ color: "red" }}>{formErrors.email}</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        defaultValue={formData.email}
                        name="email"
                        placeholder="Enter Email ID"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputPhonenumber">
                        Mobile Number *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.mobile_number}
                        </span>
                      </label>
                      <input
                        type="text"
                        id="mobile_number"
                        className="form-control"
                        defaultValue={formData.mobile_number}
                        name="mobile_number"
                        placeholder="Enter Mobile Number"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            mobile_number: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputStatus">
                        Role *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.role_id}
                        </span>
                      </label>
                      <Select
                        name="role_id"
                        options={roleList}
                        value={roleList.filter((option) => {return option.value === formData.role_id})}
                        onChange={(val) => {
                          if (val.value === 5 || val.value === 6) {
                            setWarehoueStyle("form-group");
                          } else {
                            setWarehoueStyle("form-group hide_display");
                            setFormData({ ...formData, warehouse_id: "" });
                          }
                          setFormData({ ...formData, role_id: val.value });
                        }}
                      />
                    </div>

                    <div className={warehouse_style}>
                      <label htmlFor="inputProjectLeader">
                        Warehouse *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.warehouse_id}
                        </span>
                      </label>
                      <select
                          name="warehouse_id"
                          className="form-control"
                          value={formData.warehouse_id || ""}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFormData({
                              ...formData,
                              warehouse_id: e.target.value,
                            });
                          }}
                        >
                          <option key={9999} value="">
                            Select Warehouse
                          </option>
                          {warehouseList.map((item, index) => (
                            <option key={index} value={item.warehouse_id}>
                              {item.display_name}
                            </option>
                          ))}
                        </select>

                    </div>

                    <div className="col-12 offset-md-6">
                      <input
                        type="submit"
                        defaultValue="Save Changes"
                        className="btn btn-primary"
                      />
                    </div>
                  </div>
                </form>
                {/* /.card-body */}
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card" style={{ padding: "10px" }}>
                <div className="card-header">
                  <h3 className="card-title">Partner Agent List</h3>
                  <div className="card-tools">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={toggleDivVisibility}
                    >
                      {isDivVisible === false ? "Add New Agent" : ""}
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <MyDataTable
                    reloadList={reloadList}
                    columns={columns}
                    getDataListURL={`getpartneragentlist?partner_id=${partnerId}&is_default=${partnerDetails.is_default}`}
                    setReload={setReload}
                    exportName={"partneragentlist"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerLogins;
