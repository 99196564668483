import React, { useEffect, useState } from "react";
import { LABEL_JOB } from "../../../constant/label_constant";
import MainBody from "../../../component/MainBody";
import { Link, useNavigate, useParams } from "react-router-dom";
import httpRequest from "../../../utils/Http";
import {
  CANCELLED_STATUS,
  EMPLOYEE_TYPE,
  PART_ESTIMATE_TO_BE_APPROVED,
  PART_INTRANSIT,
  PART_ORDERED,
  PENDING_STATUS,
} from "../../../constant/status_constant";
import { toast } from "react-toastify";
import { decryptData, getPartWarranty } from "../../../utils/HelperFunction";
import { useSelector } from "react-redux";
import { COMPLETION_ACTION_PERMISSION, FINAL_ClOSURE } from "../../../constant/action_permission";

const CompleteBooking = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    charges: {},
    upcountry: {
      consumption: 1,
      amount_paid: 0,
      customer_payable: 0
    },
    spare: {},
    isSpare: 0,
  });

  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [bookingData, setBookingDta] = useState({});
  const [bookingUpcountry, setBookingUpcountry] = useState({});
  const [userApplianceDetails, setUserApplianceDetails] = useState({});
  const [bookingCharges, setBookingCharges] = useState({});
  const [spareParts, setSpareParts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [otpRequired, setOtpRequired] = useState(true)
  const [otpData, setOtpData] = useState("")
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);

  const { bid } = useParams();
  useEffect(() => {
    (async () => {
      if (bid) {
        try {
          const id = atob(bid);
          if (id) {
            console.log(id)
            const data = await httpRequest(
              "getbookingdetails?ishistory=0&id=" + id,
              {},
              "GET"
            );

            if (data.status == true) {
              const isbValidate = bookingDetailsValidation(data.bookingData);
              if (isbValidate) {
                const isSValidate = spareValidation(data.spareParts);
                if (isSValidate) {

                  const upcountry =
                    data.bookingUpcountry.length > 0 &&
                    data.bookingUpcountry[0].is_upcountry == 1
                      ? 1
                      : 0;
                  let charges = {};
                  let spare = {};
                  let upcountryObject = {
                    consumption: 1,
                    amount_paid: 0,
                    customer_payable: data.bookingUpcountry.length > 0 && data.bookingUpcountry[0].upcountry_paid_by ==
                      2
                        ? data.bookingUpcountry[0]
                            .upcountry_sf_rate *
                          data.bookingUpcountry[0].upcountry_distance
                        : 0
                  } 

                  if (data.bookingCharges.length > 0) {
                    data.bookingCharges.forEach((item) => {
                      charges[item.id] = {
                        amount_paid: 0,
                        consumption: 1,
                        customer_payable: item.customer_payable,
                      };
                    });
                  }
                  let isSpare = 0;
                  if (data.spareParts.length > 0) {
                    data.spareParts.forEach((item) => {
                      if (!item.consumption_id && item.status !== CANCELLED_STATUS) {
                        isSpare += 1;
                        spare[item.id] = {
                          amount_paid: 0,
                          consumption: 1,
                          customer_payable:
                            item.warranty_status === 2
                              ? item.estimate_price
                              : 0,
                        };
                      }
                    });
                  }

                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    bid: id,
                    isSpare: isSpare,
                    isUpcountry: upcountry,
                    charges: charges,
                    spare: spare,
                    upcountry: upcountryObject
                  }));

                  setBookingDta(data.bookingData);
                  setUserApplianceDetails(data.userApplianceDetails);
                  setBookingUpcountry(data.bookingUpcountry);
                  setBookingCharges(data.bookingCharges);
                  setSpareParts(data.spareParts);
                }
              }
            } else {
              navigate("/404");
            }
          } else {
            navigate("/404");
          }
        } catch (error) {
          console.log(error);
          navigate("/404");
        }
      }
    })();
  }, [bid]);

  useEffect(() => {
    if(bookingData){
      (async () => {
        const otpData = await httpRequest(
          "getovalidation", {bid: atob(bid)}, "POST"
        )
  
        if(otpData.status === true){
          if(otpData.data){
            setOtpRequired(true)
            setOtpData(otpData.data)
          } else {
            setOtpRequired(false)
          }
        }
      })()
    }
  }, [bookingData])



  const bookingDetailsValidation = (data) => {
    if (data.current_status !== PENDING_STATUS  && loggedInUser.entity_type != EMPLOYEE_TYPE) {
      toast.error(`${LABEL_JOB} already closed.`);
      setFormErrors({
        ...formErrors,
        bookingValidaton: `${LABEL_JOB} already closed.`,
      });
      navigate("/pendingbooking");
      return false;
    }

    if (data.sc_closed_date && loggedInUser.entity_type != EMPLOYEE_TYPE) {
      toast.error(`${LABEL_JOB} already closed.`);
      setFormErrors({
        ...formErrors,
        bookingValidaton: `${LABEL_JOB} already closed.`,
      });
      navigate("/pendingbooking");
      return false;
    }

    if (data.closed_date && !(Number(loggedInUser.entity_type) === EMPLOYEE_TYPE && COMPLETION_ACTION_PERMISSION.includes(loggedInUser.role_id))) {
      toast.error(`${LABEL_JOB} already closed.`);
      setFormErrors({
        ...formErrors,
        bookingValidaton: `${LABEL_JOB} already closed.`,
      });
      navigate("/pendingbooking");
      return false;
    }

    if (!data.assigned_sc_id) {
      toast.error(
        `${LABEL_JOB} is not assigned to any service center. You cannot allow to complete this booking`
      );
      setFormErrors({
        ...formErrors,
        bookingValidaton: `${LABEL_JOB} is not assigned to any service center. You cannot allow to complete this booking`,
      });
      navigate("/pendingbooking");
      return false;
    }

    return true;
  };

  const spareValidation = (data) => {
    let partStatus = "";

    data.forEach((item) => {
      switch (item.status) {
        case PART_ORDERED:
          partStatus =
            "Parts in Ordered State. Cancel the Part or Wait for Shipment.";
          break;
        case PART_INTRANSIT:
          partStatus = "Part is in-Transit State. Need to receive parts.";
          break;
        case PART_ESTIMATE_TO_BE_APPROVED:
          partStatus =
            "Part Estimate Given. Firstly Approve the estimate then complete.";
          break;
        default:
          break;
      }

      if (partStatus) {
        toast.error(partStatus);
        navigate("/pendingbooking");
        return false;
      }
    });

    return true;
  };

  const handleAmountChange = (e, id, type) => {
    const value = parseFloat(e.target.value) || 0;
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      if (type === "charges") {
        updatedData.charges[id] = {
          ...updatedData.charges[id],
          amount_paid: Number(value),
          consumption:
            value > 0
              ? 1
              : Number(updatedData.charges[id].customer_payable) === 0
              ? 1
              : 2,
        };
      } else if (type === "spare") {
        updatedData.spare[id] = {
          ...updatedData.spare[id],
          amount_paid: Number(value),
          consumption: value > 0 ? 1 : 2,
        };
      } else if (type === "upcountry") {
        updatedData.upcountry = {
          ...updatedData.upcountry,
          amount_paid: Number(value),
          consumption: 1,
        };
      }

      return updatedData;
    });
  };

  const handleActionChange = (e, id, type) => {
    const value = Number(e.target.value) === 1 ? 1 : 2;
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      if (type === "charges") {
        updatedData.charges[id] = {
          ...updatedData.charges[id],
          consumption: value,
        };
      } else if (type === "spare") {
        updatedData.spare[id] = {
          ...updatedData.spare[id],
          consumption: value,
        };
      } else if (type === "upcountry") {
        updatedData.upcountry = {
          ...updatedData.upcountry,
          consumption: value,
        };
      }

      return updatedData;
    });
  };

  useEffect(() => {
    const totalCharges = Object.values(formData.charges).reduce(
      (acc, charge) => acc + (charge.amount_paid || 0),
      0
    );
    const totalSpare = Object.values(formData.spare).reduce(
      (acc, part) => acc + (part.amount_paid || 0),
      0
    );

    const totalUpcountry = formData.upcountry.amount_paid || 0;

    setTotalAmount(
      Number(totalCharges) + Number(totalSpare) + Number(totalUpcountry)
    );
  }, [formData]);

  const validateForm = () => {
    const newErrors = {};
    let isConsumption = false;
    const checkConsumed = (items, type) => {
      
      if (Object.keys(items).length > 0) {
        Object.entries(items).forEach(([key, value]) => {
          console.log(value.consumption)
          if (value.consumption === undefined) {
            console.log('undefineddd')
            newErrors[`${type}_${key}`] =
              "Please select Completed/Not Completed";
          }
          if (Number(value.consumption) !== 1 && Number(value.consumption) !== 2) {
            console.log("hhhh")
            newErrors[`${type}_${key}`] =
              "Please select Completed/Not Completed";
          }
          if (
            value.consumption === 1 &&
            Number(value.amount_paid) <= 0 &&
            Number(value.customer_payable) > 0
          ) {
            console.log(Number(value.amount_paid));
            // console.log(Number(value.customer_payable))
            newErrors[`${type}_amount_${key}`] =
              "Amount should be greater than zero for Completed";
          }

          if (value.consumption === 1) {
            isConsumption = true;
          }
        });
        console.log('consumption:', isConsumption)
        if (!isConsumption) {
          newErrors[type] = "Please select Completed/Not Completed";
        }
      } else {
        newErrors[type] = "Action should be mandatory";
      }
    };

    checkConsumed(formData.charges, "charges");
    if (formData.isSpare > 0) {
      checkConsumed(formData.spare, "spare");
    }

    if (formData.isUpcountry === 1) {
      if (formData.upcountry.consumption !== 1) {
        newErrors.upcountry = "Upcountry action should be mandatory";
      }

      if (
        formData.upcountry.consumption === 1 &&
        Number(formData.upcountry.amount_paid) <= 0
        && Number(formData.upcountry.customer_payable) > 0
      ) {
        newErrors.upcountry_amount =
          "Amount should be greater than zero for completed";
      }
    }

    if (!formData.remarks) {
      newErrors.remarks = "Remarks is mandatory";
    }

    if(FINAL_ClOSURE.includes(loggedInUser.role_id)){
      setOtpRequired(false)
    }

    if(otpRequired && !formData.otp){
      newErrors.otp = "OTP is mandatory";
    }

    if(otpRequired && formData.otp){
      const otp = decryptData(otpData, process.env.REACT_APP_SECRET_KEY)
      if(Number(otp.data) !== Number(formData.otp)){
        newErrors.otp = "OTP is invalid";
      }
    }

    console.log("Errors:", newErrors); // Added log

    return newErrors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest(
          "processcompletebookingbysf",
          formData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          navigate("/pendingbooking");
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  const resendOtp = () => {
    (async () => {
      const otpData = await httpRequest(
        "resendbookingotp", {bid: atob(bid)}, "POST"
      )

      if(otpData.status === true){
        if(otpData.data){
          setOtpRequired(true)
          setOtpData(otpData.data)
          toast.success("OTP Sent to Customer Mobile Number")
        } else {
         // setOtpRequired(false)
         toast.error("OTP Send Failed")
        }
      }
    })()
  }

  return (
    <div>
      <MainBody
        breadcrumbTitle={`Complete ${LABEL_JOB}`}
        breadcrumbSubTitle={formData.booking_id ? formData.booking_id : ""}
        navButton={""}
        title={""}
        component={
          <>
            {bookingData && (
              <section className="content">
                <div className="container-fluid">
                  <form id="addbasicdetails" onSubmit={onSubmitHandler}>
                    <div className="card card-default">
                      <div className="card-header">
                        <h3 className="card-title">Step 1</h3>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Customer Name </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={
                                    bookingData?.user_detail?.customer_name
                                  }
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Customer Mobile </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={
                                    bookingData?.booking_primary_mobile_number
                                  }
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Order Id / Invoice Id</label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={bookingData?.sf_invoice_id}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Purchase Date</label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={userApplianceDetails?.sf_purchase_date}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Appliance </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={bookingData?.appliance?.appliance_name}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Model Number </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={userApplianceDetails?.model_number}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Serial Number </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={userApplianceDetails?.sf_serial_number}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Brand </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  name="appliance_name"
                                  className="form-control"
                                  value={userApplianceDetails?.brand_name}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-default">
                      <div className="card-header">
                        <h3 className="card-title">Step 2</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Service Category</th>
                                <th>Amount Due</th>
                                <th>Amount Paid</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bookingCharges.length > 0 &&
                                bookingCharges.map((item) => (
                                  <tr key={item.id}>
                                    <td className="text-center">
                                      {item.call_type["call_type"]}
                                    </td>
                                    <td className="text-center">
                                      {item.customer_payable}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        min={0}
                                        placeholder="Enter Amount Paid"
                                        step=".2"
                                        className="form-control"
                                        onChange={(e) =>
                                          handleAmountChange(
                                            e,
                                            item.id,
                                            "charges"
                                          )
                                        }
                                      />
                                      {formErrors[
                                        `charges_amount_${item.id}`
                                      ] && (
                                        <div className="text-danger">
                                          {
                                            formErrors[
                                              `charges_amount_${item.id}`
                                            ]
                                          }
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      <span style={{ color: "red" }}>
                                        {formErrors.charges}
                                      </span>
                                      <br />
                                      <div className="form-group">
                                        <div className="custom-control custom-radio">
                                          <input
                                            className="custom-control-input"
                                            type="radio"
                                            value={1}
                                            name={`action_${item.id}`}
                                            id={`completed_${item.id}`}
                                            onChange={(e) =>
                                              handleActionChange(
                                                e,
                                                item.id,
                                                "charges"
                                              )
                                            }
                                            checked={
                                              formData.charges[item.id]
                                                ?.consumption === 1
                                            }
                                          />
                                          <label
                                            htmlFor={`completed_${item.id}`}
                                            className="custom-control-label"
                                          >
                                            Completed
                                          </label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                          <input
                                            className="custom-control-input custom-control-input-danger"
                                            value={0}
                                            id={`not_completed_${item.id}`}
                                            type="radio"
                                            name={`action_${item.id}`}
                                            onChange={(e) =>
                                              handleActionChange(
                                                e,
                                                item.id,
                                                "charges"
                                              )
                                            }
                                            checked={
                                              formData.charges[item.id]
                                                ?.consumption === 2
                                            }
                                          />
                                          <label
                                            htmlFor={`not_completed_${item.id}`}
                                            className="custom-control-label"
                                          >
                                            Not Completed
                                          </label>
                                        </div>
                                        {formErrors[`charges_${item.id}`] && (
                                          <div className="text-danger">
                                            {formErrors[`charges_${item.id}`]}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}

                              {bookingUpcountry.length > 0 &&
                                bookingUpcountry[0].is_upcountry == 1 && (
                                  <tr key={"upcountry"} id={"call_upcountry"}>
                                    <td className="text-center">Upcountry</td>
                                    <td className="text-center">
                                      {bookingUpcountry[0].upcountry_paid_by ==
                                      2
                                        ? bookingUpcountry[0]
                                            .upcountry_sf_rate *
                                          bookingUpcountry[0].upcountry_distance
                                        : 0}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        placeholder="Enter Amount Paid"
                                        className="form-control"
                                        name="upcountry"
                                        min={0}
                                        step=".2"
                                        id={"charges_upcountry"}
                                        onChange={(e) =>
                                          handleAmountChange(
                                            e,
                                            null,
                                            "upcountry"
                                          )
                                        }
                                      />
                                      {formErrors.upcountry_amount && (
                                        <div className="text-danger">
                                          {formErrors.upcountry_amount}
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      <span style={{ color: "red" }}>
                                        {formErrors.upcountry}
                                      </span>
                                      <br />
                                      <div className="form-group">
                                        <div className="custom-control custom-radio">
                                          <input
                                            className="custom-control-input"
                                            type="radio"
                                            value={1}
                                            name={`upcountry`}
                                            id={`completed_upcountry`}
                                            onChange={(e) =>
                                              handleActionChange(
                                                e,
                                                null,
                                                "upcountry"
                                              )
                                            }
                                            checked={
                                              formData.upcountry.consumption ===
                                              1
                                            }
                                          />
                                          <label
                                            htmlFor={`completed_upcountry`}
                                            className="custom-control-label"
                                          >
                                            Completed
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {spareParts.length > 0 && (
                      <div className="card card-default">
                        <div className="card-header">
                          <h3 className="card-title">Step 3</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <table className="table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>Shipped Part Number</th>
                                  <th>Warranty Status</th>
                                  <th>Amount Due</th>
                                  <th>Amount Paid</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {spareParts.map((item) =>
                                  (!item.consumption_id && item.status !== CANCELLED_STATUS)  ? (
                                    <tr key={item.id}>
                                      <td className="text-center">{`${item.shipped_part["part_number"]} - ${item.shipped_part["part_name"]}`}</td>
                                      <td className="text-center">
                                        {getPartWarranty(item.warranty_status)}
                                      </td>
                                      <td className="text-center">
                                        {item.estimate_price}
                                      </td>
                                      <td>
                                        {item.warranty_status === 2 && (
                                          <input
                                            type="number"
                                            min={0}
                                            placeholder="Enter Amount Paid"
                                            step=".2"
                                            className="form-control"
                                            onChange={(e) =>
                                              handleAmountChange(
                                                e,
                                                item.id,
                                                "spare"
                                              )
                                            }
                                          />
                                        )}
                                        {formErrors[
                                          `spare_amount_${item.id}`
                                        ] && (
                                          <div className="text-danger">
                                            {
                                              formErrors[
                                                `spare_amount_${item.id}`
                                              ]
                                            }
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <span style={{ color: "red" }}>
                                          {formErrors.spare}
                                        </span>
                                        <br />
                                        <div className="form-group">
                                          <div className="custom-control custom-radio">
                                            <input
                                              className="custom-control-input"
                                              type="radio"
                                              value={1}
                                              name={`spare_action_${item.id}`}
                                              id={`spare_completed_${item.id}`}
                                              onChange={(e) =>
                                                handleActionChange(
                                                  e,
                                                  item.id,
                                                  "spare"
                                                )
                                              }
                                              checked={
                                                formData.spare[item.id]
                                                  ?.consumption === 1
                                              }
                                            />
                                            <label
                                              htmlFor={`spare_completed_${item.id}`}
                                              className="custom-control-label"
                                            >
                                              Part Consumed
                                            </label>
                                          </div>
                                          <div className="custom-control custom-radio">
                                            <input
                                              className="custom-control-input custom-control-input-danger"
                                              type="radio"
                                              value={0}
                                              name={`spare_action_${item.id}`}
                                              id={`spare_not_completed_${item.id}`}
                                              onChange={(e) =>
                                                handleActionChange(
                                                  e,
                                                  item.id,
                                                  "spare"
                                                )
                                              }
                                              checked={
                                                formData.spare[item.id]
                                                  ?.consumption === 2
                                              }
                                            />
                                            <label
                                              htmlFor={`spare_not_completed_${item.id}`}
                                              className="custom-control-label"
                                            >
                                              Part Not Consumed
                                            </label>
                                          </div>
                                          {formErrors[`spare_${item.id}`] && (
                                            <div className="text-danger">
                                              {formErrors[`spare_${item.id}`]}
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ) : null
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="card card-default">
                      <div className="card-header">
                        <h3 className="card-title">Submit</h3>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Total Amount Paid</label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  placeholder="Total Amount"
                                  name="total"
                                  className="form-control"
                                  value={totalAmount}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>

                          {otpRequired && (
                            <div className="col-md-3">
                            <div className="form-group">
                              <label>Enter OTP * {" "}<span style={{ color: "red" }}>
                                  {formErrors.otp}
                                </span></label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  placeholder="Enter OTP"
                                  name="otp"
                                  value={formData.otp}
                                  className="form-control"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      otp: e.target.value
                                    })
                                  }}
                                />
                              </div>
                              Click here to <Link to={"#"} onClick={resendOtp}>Resend OTP</Link>
                            </div>
                          </div>
                          )}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>
                                Remarks *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.remarks}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  placeholder="Enter your remark"
                                  name="reamrks"
                                  className="form-control"
                                  value={formData.remarks}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      remarks: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>.</label>
                            <button
                              type="submit"
                              id="submit"
                              className="btn btn-primary col-md-12"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            )}
          </>
        }
      />
    </div>
  );
};

export default CompleteBooking;
